import { Refresh } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();
  return (
    <Stack role="alert" textAlign="center" alignItems="center" justifyContent="center" spacing={1}>
      <Typography variant="subtitle2">{t('somethingWentWrong', 'Что-то пошло не так...')}:</Typography>
      <Typography variant="body2" component="code">
        {error.message}
      </Typography>
      <Button onClick={resetErrorBoundary} startIcon={<Refresh />} variant="outlined" color="info">
        {t('action.refresh', 'Обновить')}
      </Button>
    </Stack>
  );
};
