import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetManufacturersQuery } from '../../../app/services/manufacturersApi';
import { useCreateModelMutation, useUpdateModelMutation } from '../../../app/services/modelsApi';
import { handleRequestError } from '../../../helpers/form';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { modelValidationSchema } from './schema';

interface Props {
  data?: any;
  categories: RecordObject[];
}

const ModelsForm: FC<Props> = ({ data, categories }) => {
  const { t, locale } = useLocale();
  const navigate = useNavigate();

  const query = data ? useUpdateModelMutation : useCreateModelMutation;
  const { data: manufacturers } = useGetManufacturersQuery({ limit: 1000 });

  const [save, { isLoading }] = query();

  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'product_category_id',
          label: t('category', 'Категория'),
          type: 'select',
          width: 6,
          options: categories.map((category: RecordObject) => ({
            label: category.name[locale],
            value: category.id,
          })),
          required: true,
        },
        {
          name: 'manufacturer_id',
          label: t('manufacturer', 'Производитель'),
          type: 'select',
          width: 6,
          options: manufacturers?.data.items.map((man: any) => ({
            value: man.id,
            label: man.name,
          })),
          required: true,
        },
        {
          name: 'name',
          label: t('name', 'Название'),
          type: 'text',
          width: 6,
          required: true,
        },
        {
          name: 'code',
          label: t('code', 'Код'),
          type: 'text',
          width: 6,
          required: true,
        },
        {
          name: 'description',
          label: t('description', 'Описание'),
          type: 'textarea',
          width: 12,
        },
        // {
        //   name: "description.ru",
        //   label: t("description.ru", "Описание на русском"),
        //   type: "textarea",
        //   width: 4,
        // },
        // {
        //   name: "description.uz",
        //   label: t("description.uz", "Описание на узбекском"),
        //   type: "textarea",
        //   width: 4,
        // },
        // {
        //   name: "description.en",
        //   label: t("description.en", "Описание на английском"),
        //   type: "textarea",
        //   width: 4,
        // },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      product_category_id: '',
      name: '',
      code: '',
      manufacturer_id: '',
      description: '',
      // description: { ru: "", uz: "", en: "" },

      ...data,
    },
    validationSchema: modelValidationSchema,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then(() => {
          toast.success(t('model.save.success', 'Модель успешно сохранена'));
          navigate('..');
        })
        .catch((error) => handleRequestError(error, formik));
    },
  });

  if (!manufacturers) return null;

  return (
    <Stack component={'form'} gap={2} onSubmit={formik.handleSubmit}>
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id} p={2} component={Card}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button size="large" variant="contained" color="inherit">
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading || !formik.isValid}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default ModelsForm;
