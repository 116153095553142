import { FC } from 'react';
import { useGetApplicationListQuery } from '../../app/services/applicationsApi';
import LayoutList from '../../components/LayoutList';
import { renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const ActListPage: FC<Props> = () => {
  const { t } = useLocale();

  const cols = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'fullname',
      label: t('fullname', 'ФИО'),
      render: (row: RecordObject) => renderFullName(row.client),
    },
    {
      id: 'passport',
      label: t('passport', 'Паспорт'),
      render: (row: any) => [row.client.passport_series, row.client.passport_number].join(' '),
    },
    {
      id: 'contacts',
      label: t('contacts', 'Контакты'),
      render: (row: any) => [row.client.phone_1, row.client.phone_2, row.client.email].join(', '),
    },
    {
      id: 'guarantor',
      label: t('guarantor', 'Поручитель'),
      render: (row: any) => renderFullName(row, 'guarantor_1'),
    },
    // {
    //   id: "note",
    //   label: t("note", "Примечание"),
    // },
  ];

  return <LayoutList title={t('acts', 'Акты')} cols={cols} query={useGetApplicationListQuery} noAdd noEdit />;
};

export default ActListPage;
