import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateSupplierMutation, useUpdateSupplierMutation } from '../../../app/services/suppliersApi';
import { handleRequestError } from '../../../helpers/form';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { supplierValidationSchema } from './schema';

interface Props {
  data?: any;
}

const SupplierForm: FC<Props> = ({ data }) => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const query = data ? useUpdateSupplierMutation : useCreateSupplierMutation;
  const [save, { isLoading }] = query();

  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'merchant.contact_1_last_name',
          label: t('last_name', 'Фамилия'),
          type: 'text',
          width: 4,
          required: true,
        },
        {
          name: 'merchant.contact_1_first_name',
          label: t('first_name', 'Имя'),
          type: 'text',
          width: 4,
          required: true,
        },
        {
          name: 'merchant.contact_1_second_name',
          label: t('middle_name', 'Отчество'),
          type: 'text',
          width: 4,
        },
        {
          name: 'merchant.is_legal',
          label: t('legalEntity', 'Юр. лицо') + '?',
          type: 'select',
          width: 6,
          required: true,
          options: [
            { value: '1', label: t('yes', 'Да') },
            { value: '0', label: t('no', 'Нет') },
          ],
        },
        {
          name: 'merchant.company_name',
          label: t('company_name', 'Название компании'),
          type: 'text',
          width: 6,
          required: true,
        },
        {
          name: 'merchant.contact_1_phone',
          label: t('phoneNumber', 'Номер телефона'),
          type: 'phone',
          width: 6,
          required: true,
        },
        {
          name: 'merchant.contact_1_email',
          label: t('email', 'Email'),
          type: 'text',
          width: 6,
        },
        {
          name: 'address',
          label: t('address', 'Адрес'),
          type: 'group',
          width: 12,
          required: true,
          fields: [
            {
              name: 'merchant.location_id',
              type: 'location',
              width: 4,
              required: true,
            },
            {
              name: 'merchant.company_legal_address',
              type: 'text',
              width: 8,
            },
          ],
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      ...data,
      merchant: {
        contact_1_last_name: '',
        contact_1_first_name: '',
        contact_1_second_name: '',
        contact_1_phone: '',
        contact_1_email: '',
        is_legal: '0',
        company_type: 'OOO',
        company_name: '',
        company_legal_address: '',
        location_id: data?.merchant.location.id,
        ...data?.merchant,
      },
    },
    validationSchema: supplierValidationSchema,
    onSubmit: (values, actions) => {
      save(values)
        .unwrap()
        .then(() => {
          toast.success(t('supplier.save.success', 'Поставщик успешно сохранен'));
          navigate('/dashboard/suppliers');
        })
        .catch((error) => handleRequestError(error, formik));
    },
  });

  return (
    <Stack component={'form'} gap={2} onSubmit={formik.handleSubmit}>
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button size="large" variant="contained" color="inherit" onClick={() => navigate('/dashboard/suppliers')}>
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading || !formik.isValid}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default SupplierForm;
