import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Drawer, Stack, Toolbar } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';

interface SidebarProps {}

const Sidebar: ComponentType<SidebarProps> = () => {
  const [open, setOpen] = useState<boolean>(true);

  const color = 'background';

  useEffect(() => {
    localStorage.setItem('sidebarOpen', String(open));
  }, [open]);

  const drawerWidth = open ? 250 : 48;

  return (
    <>
      <Box
        sx={{
          width: drawerWidth,
          transition: '.3s',
          flexShrink: 0,
        }}
      />
      <Drawer
        variant="permanent"
        open={open}
        elevation={0}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            transition: '.3s',
            overflow: 'hidden',
            backgroundColor: `${color}.main`,
            color: `${color}.contrastText`,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            '& .MuiToolbar-root': {
              pr: 0.5,
            },
          }}
        >
          <Toolbar
            sx={{
              width: 250,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Stack alignItems="flex-end" component={Link} to="/dashboard" color="primary.main">
              <Box component="img" src="/logo.png" alt="Taqseet" width={160} />
            </Stack>
            <Button
              onClick={() => setOpen(!open)}
              sx={{
                minWidth: 0,
                color: `inherit`,
                ml: 1,
              }}
            >
              {open ? <ChevronLeft /> : <ChevronRight />}
            </Button>
          </Toolbar>
        </Box>

        <Navigation sidebarOpen={open} />
      </Drawer>
    </>
  );
};

export default Sidebar;
