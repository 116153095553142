import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { rtkQueryErrorLogger } from '../helpers/rtkquery';
import { authApi } from './services';
import { applicationsApi } from './services/applicationsApi';
import { callCenterApi } from './services/callCenterApi';
import { clientsApi } from './services/clientsApi';
import { locationsApi } from './services/locationsApi';
import { manufacturersApi } from './services/manufacturersApi';
import { modelsApi } from './services/modelsApi';
import { productCategoriesApi } from './services/productCategoriesApi';
import { productsApi } from './services/productsApi';
import { settingsApi } from './services/settingsApi';
import { smsApi } from './services/smsApi';
import { suppliersApi } from './services/suppliersApi';

export const store = configureStore({
  reducer: {
    authApi: authApi.reducer,
    productsApi: productsApi.reducer,
    productCategoriesApi: productCategoriesApi.reducer,
    manufacturersApi: manufacturersApi.reducer,
    modelsApi: modelsApi.reducer,
    clientsApi: clientsApi.reducer,
    suppliersApi: suppliersApi.reducer,
    locationsApi: locationsApi.reducer,
    applicationsApi: applicationsApi.reducer,
    callCenterApi: callCenterApi.reducer,
    smsApi: smsApi.reducer,
    settingsApi: settingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      productsApi.middleware,
      productCategoriesApi.middleware,
      manufacturersApi.middleware,
      modelsApi.middleware,
      clientsApi.middleware,
      suppliersApi.middleware,
      locationsApi.middleware,
      applicationsApi.middleware,
      callCenterApi.middleware,
      smsApi.middleware,
      settingsApi.middleware,

      rtkQueryErrorLogger
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
