import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const modelsApi = createApi({
  reducerPath: 'modelsApi',
  baseQuery: rtkBaseQuery('/models'),
  tagTypes: ['Models'],
  endpoints: (builder) => ({
    getModels: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['Models'],
    }),
    getModelById: builder.query({
      query: (id) => `${id}`,
      providesTags: ['Models'],
    }),
    createModel: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Models'],
    }),
    updateModel: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'POST',
        body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, { id }) => ['Models', { type: 'Models', id }],
    }),
  }),
});

export const { useGetModelsQuery, useGetModelByIdQuery, useCreateModelMutation, useUpdateModelMutation } = modelsApi;
