import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductByIdQuery } from '../../app/services/productsApi';
import ContentLayout from '../../components/ContentLayout';
import { CURRENCY_SUFFIX } from '../../constants/vars';
import { renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const ProductDetailsPage: ComponentType<Props> = () => {
  const { t, locale } = useLocale();
  const { id } = useParams();
  const { data: response } = useGetProductByIdQuery(id);

  if (!response) return null;

  const data = response.data;

  const rows = [
    {
      id: 'product_category',
      label: t('category', 'Категория'),
      value: data.product_category.name[locale],
    },
    {
      id: 'brand',
      label: t('brand', 'Марка'),
      value: data.manufacturer.name,
    },
    {
      id: 'model',
      label: t('model', 'Модель'),
      value: data.model.name,
    },
    {
      id: 'serial_1',
      label: t('serial', 'Серийный номер'),
      value: data.serial_id_1,
    },
    {
      id: 'serial_2',
      label: t('serial', 'Серийный номер') + ' 2',
      value: data.serial_id_2,
    },
    {
      id: 'imei_1',
      label: t('imei', 'IMEI'),
      value: data.imei_1,
    },
    {
      id: 'imei_2',
      label: t('imei', 'IMEI') + ' 2',
      value: data.imei_2,
    },
    {
      id: 'supplier',
      label: t('supplier', 'Поставщик'),
      value: renderFullName(data.merchant.merchant, 'contact_1'),
    },
    {
      id: 'price_income',
      label: t('price', 'Цена'),
      value: data.price_income + CURRENCY_SUFFIX,
    },
    {
      id: 'date_income',
      label: t('goods.arriveDate', 'Дата прихода'),
      value: new Date(data.date_income).toLocaleDateString('ru'),
    },
    {
      id: 'note',
      label: t('note', 'Примечание'),
      value: data.note,
    },
    {
      id: 'is_sold',
      label: t('sold', 'Продано'),
      value: data.is_sold ? 'Да' : 'Нет',
    },
  ];

  const params = data.fields;

  return (
    <ContentLayout title={data.title[locale]}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" mb={2}>
            {t('general_info', 'Общая информация')}
          </Typography>
          <Table>
            <TableBody>
              {rows.map(
                (row: any) =>
                  row.value && (
                    <TableRow>
                      <TableCell>{row.label}</TableCell>
                      <TableCell align="right">
                        {row.id === 'params'
                          ? row.value &&
                            typeof row.value === 'string' &&
                            row.value.map((param: any) => param.name + ': ' + param.value)
                          : row.value}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </Grid>
        {params && params.length > 0 && (
          <Grid item xs={6}>
            <Typography variant="h6" mb={2}>
              {t('params', 'Параметры')}
            </Typography>
            <Table>
              <TableBody>
                {params.map((param: any) => (
                  <TableRow>
                    <TableCell>{param.name}</TableCell>
                    <TableCell align="right">{param.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </ContentLayout>
  );
};

export default ProductDetailsPage;
