import { Button, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { convertLocalizedDate } from '../helpers/utils';

interface Props {
  type: 'full' | 'search';
}

const ListFilter: FC<Props> = ({ type }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams.get('q'));
  const [dateFrom, setDateFrom] = useState(convertLocalizedDate(searchParams.get('date_from')));
  const [dateTo, setDateTo] = useState(convertLocalizedDate(searchParams.get('date_to')));

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      const values = {
        q: search,
        date_from: dateFrom ? dateFrom.toLocaleDateString('ru') : null,
        date_to: dateTo ? dateTo.toLocaleDateString('ru') : null,
      };
      // filter object `values` from empty values or not existing
      const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, string>);
      setSearchParams(filteredValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, dateFrom, dateTo]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" mb={3} gap={2}>
        <TextField size="small" label="Поиск" value={search} onChange={(e) => setSearch(e.target.value)} fullWidth />
        {type === 'full' && [
          <DatePicker
            value={dateFrom}
            onChange={setDateFrom}
            sx={{ width: 1, maxWidth: 160 }}
            slotProps={{
              textField: {
                label: 'Дата, от',
                size: 'small',
              },
            }}
          />,
          <DatePicker
            value={dateTo}
            onChange={setDateTo}
            sx={{ width: 1, maxWidth: 160 }}
            slotProps={{
              textField: {
                label: 'Дата, до',
                size: 'small',
              },
            }}
          />,
        ]}

        <Button
          // type="reset"
          // variant="contained"
          size="small"
          sx={{
            minWidth: 100,
          }}
          onClick={() => {
            setSearch('');
            setDateFrom(null);
            setDateTo(null);
            setSearchParams({});
          }}
        >
          Сброс
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="info"
          size="small"
          sx={{
            minWidth: 100,
          }}
        >
          Поиск
        </Button>
      </Stack>
    </form>
  );
};

export default ListFilter;
