import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetClientsQuery } from '../../app/services/clientsApi';
import LayoutList from '../../components/LayoutList';
import { renderFullName } from '../../helpers/utils';

interface Props {}

const ClientListPage: FC<Props> = () => {
  const { t } = useTranslation();
  const columns = [
    { id: 'id', label: 'ID', minWidth: 70 },
    {
      id: 'fullname',
      label: t('fullNameAbbr', 'ФИО'),
      minWidth: 130,
      render: (row: any) => renderFullName(row.client),
    },
    {
      id: 'passport',
      label: t('passport', 'Номер паспорта'),
      minWidth: 130,
      render: (row: any) => [row.client.passport_series, row.client.passport_number].join(' '),
    },
    {
      id: 'contacts',
      label: t('contacts', 'Контакты'),
      minWidth: 130,
      render: (row: any) => (
        <Stack>
          <span>{row.client?.phone_1}</span>
          {row.client.phone2 && <span>{row.client.phone2}</span>}
        </Stack>
      ),
    },
    {
      id: 'guarantor',
      label: t('guarantor', 'Поручитель'),
      minWidth: 130,
      render: (row: any) => renderFullName(row.client, 'guarantor_1'),
    },
    {
      id: 'note',
      label: t('note', 'Примечание'),
      render: (row: any) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 200,
          }}
          title={row.client.note}
        >
          {row.client.note}
        </Box>
      ),
    },
  ];
  return <LayoutList title={t('nav.customers', 'Клиенты')} cols={columns} query={useGetClientsQuery} />;
};

export default ClientListPage;
