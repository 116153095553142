import { Autocomplete } from "@mui/material";
import { styled } from "@mui/system";
import { FC, useEffect, useMemo, useState } from "react";
import FormField from "./FormField";

interface Props {
  value: any;
  onChange: any;
  error: boolean;
  helperText?: string;
  locations: LocationObject[];
  required?: boolean;
}

type LocationObject = {
  id: number;
  country_id: number;
  parent_id: number;
  name: string;
  code: string;
  type: string;
  level: string;
  weight: string;
  publication_status: boolean;
};

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: "white",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const LocationSelectorContainer: FC<Props> = ({
  value,
  onChange,
  error,
  helperText,
  locations,
}) => {
  const [selectedOption, setSelectedOption] = useState<LocationObject | null>(
    null
  );

  // collect all location's parent_ids and filter duplicates and nulls
  const parentIds = useMemo(() => {
    return locations
      .map((item: any) => item.parent_id)
      .filter((id: any, index: any, self: any) => {
        return id != null && index === self.findIndex((t: any) => t === id);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set selected option on initial load if there is value
  useEffect(() => {
    if (value) {
      const selected = locations.find(
        (item: LocationObject) => item.id === value
      );
      if (selected) setSelectedOption(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // send selected value to parent component
  useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  if (!locations) return null;

  return (
    <Autocomplete
      fullWidth
      value={selectedOption}
      onChange={(_, newValue: any) => {
        // console.log("newValue", newValue);
        setSelectedOption(newValue);
      }}
      options={locations
        .filter((opt: LocationObject) => !parentIds.includes(opt.id))
        .sort((a: LocationObject, b: LocationObject) => {
          // Move nulls to the end
          if (a.parent_id === null && b.parent_id !== null) {
            return 1;
          } else if (a.parent_id !== null && b.parent_id === null) {
            return -1;
          }

          // Sort numbers
          return a.parent_id - b.parent_id;
        })}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <FormField {...params} error={error} helperText={helperText} />
      )}
      groupBy={(option: LocationObject) => "" + option.parent_id}
      renderGroup={(params: any) => (
        <li key={params.key}>
          {params.group !== "null" && (
            <GroupHeader>
              {
                locations.find(
                  (item: LocationObject) => item.id === parseInt(params.group)
                )?.name
              }
            </GroupHeader>
          )}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

export default LocationSelectorContainer;
