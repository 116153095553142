import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetClientByIdQuery } from "../../app/services/clientsApi";
import ContentLayout from "../../components/ContentLayout";
import ClientForm from "../../components/forms/client/ClientForm";

interface Props {}

const ClientUpdatePage: FC<Props> = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data } = useGetClientByIdQuery(id);

  if (!data) return null;

  return (
    <ContentLayout title={t("customers.edit", "Редактирование клиента")}>
      <ClientForm data={data.data} />
    </ContentLayout>
  );
};

export default ClientUpdatePage;
