import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetSupplierByIdQuery } from "../../app/services/suppliersApi";
import ContentLayout from "../../components/ContentLayout";
import SupplierForm from "../../components/forms/supplier/SupplierForm";
import { renderFullName } from "../../helpers/utils";

interface Props {}

const SuppliersUpdatePage: ComponentType<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useGetSupplierByIdQuery(id);

  if (!data) return null;

  return (
    <ContentLayout
      title={
        t("suppliers.update", "Обновление поставщика") +
        ` ${renderFullName(data)}`
      }
    >
      <SupplierForm data={data.data} />
    </ContentLayout>
  );
};

export default SuppliersUpdatePage;
