import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Pagination,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { handleRequestError } from '../../helpers/form';
import { toast } from 'react-toastify';

export interface DataTableColumn {
  id: string;
  label: string;
  minWidth?: number;
  render?: (row: Record<string, any>) => JSX.Element;
}

interface DataTableProps {
  cols: DataTableColumn[];
  rows: Record<string, any>[];
  disableActions?: boolean;
  total: number;
  props: any;
  setProps: any;
  isLoading?: boolean;
  disableDetails?: boolean;
  disableEdit?: boolean;
  onDelete?: (id: number) => void;
}

const DataTable: ComponentType<DataTableProps> = ({
  cols,
  rows,
  disableActions = false,
  total,
  props,
  setProps,
  isLoading = false,
  disableDetails = false,
  disableEdit = false,
  onDelete,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleProps = (prop_name: string, prop_value: any) => {
    setProps((prev: any) => ({ ...prev, [prop_name]: prop_value }));
  };

  const handleEdit = (id: number) => {
    navigate('' + id + '/update');
  };

  return (
    <TableContainer
    // component={Paper}
    // elevation={3}
    // sx={{ borderRadius: 2, p: 0 }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  minWidth: column.minWidth,
                  bgcolor: 'background.default',
                }}
              >
                {column.label}
              </TableCell>
            ))}
            {!disableActions && (
              <TableCell key="actions" align="center" sx={{ minWidth: 0, bgcolor: 'background.default' }} />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell sx={{ border: 0, p: 3 }} colSpan={cols.length + (disableActions ? 0 : 1)} align="center">
                {t('loading', 'Загрузка...')}
              </TableCell>
            </TableRow>
          )}
          {!isLoading && total === 0 && (
            <TableRow>
              <TableCell sx={{ border: 0, p: 3 }} colSpan={cols.length + (disableActions ? 0 : 1)} align="center">
                {t('no_data', 'Нет данных')}
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            rows.map((row: RecordObject) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => (!disableDetails ? navigate('' + row.id) : {})}
                  sx={{ cursor: 'pointer' }}
                >
                  {cols.map((column) => (
                    <TableCell key={column.id}>{column.render ? column.render(row) : row[column.id]}</TableCell>
                  ))}
                  {!disableActions && (
                    <TableCell key="actions" align="right" sx={{ p: 1 }}>
                      <Box display="flex" gap={1} alignItems="center">
                        {!disableEdit && (
                          <IconButton
                            color="info"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(row.id);
                            }}
                          >
                            <SvgIcon component={Edit} fontSize="small" />
                          </IconButton>
                        )}

                        {onDelete && (
                          <IconButton
                            color="error"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (window.confirm(t('confirm_delete', 'Вы действительно хотите удалить?'))) {
                                onDelete(row.id);
                              }
                            }}
                          >
                            <SvgIcon component={Delete} fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
        {total > 0 && (
          <TableFooter>
            <TableRow>
              <TableCell sx={{ border: 0 }} colSpan={cols.length + (disableActions ? 0 : 1)}>
                <Stack alignItems="flex-end">
                  <Pagination
                    shape="rounded"
                    count={Math.ceil(total / 10)}
                    // page={props.page}
                    onChange={(e, page) => handleProps('page', page)}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default DataTable;
