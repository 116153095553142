import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

const NavigationItem: ComponentType<NavItemProps> = ({ titleKey, title, path, icon: Icon, sidebarOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const label = t(titleKey, title);
  const itemProps = path ? { to: path, component: NavLink } : { onClick: () => {} };
  return (
    <Tooltip title={t(titleKey, title)} placement="right" arrow disableHoverListener={sidebarOpen}>
      <ListItemButton
        {...itemProps}
        sx={{
          minHeight: 48,
          justifyContent: sidebarOpen ? 'initial' : 'center',
          px: sidebarOpen ? 2.5 : 1.5,
          transition: '.3s',
        }}
        selected={location.pathname.startsWith('' + path)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: sidebarOpen ? 2 : 'auto',
            justifyContent: 'center',
            color: 'inherit',
          }}
        >
          {Icon && <Icon />}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={label}
          sx={{
            opacity: sidebarOpen ? 1 : 0,
            whiteSpace: 'nowrap',
            typography: 'body2',
          }}
        />
      </ListItemButton>
    </Tooltip>
  );
};

export default NavigationItem;
