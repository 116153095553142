import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetModelByIdQuery } from "../../../app/services/modelsApi";
import { useGetProductCategoriesQuery } from "../../../app/services/productCategoriesApi";
import ContentLayout from "../../../components/ContentLayout";
import ModelsForm from "../../../components/forms/model/ModelsForm";

interface Props {}

const ModelsUpdatePage: FC<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useGetModelByIdQuery(id);
  const { data: categoriesData } = useGetProductCategoriesQuery({
    limit: 999,
    sort: "id",
  });

  if (!categoriesData || !data) return null;

  return (
    <ContentLayout title={t("models.edit", "Редактировать модель")}>
      <ModelsForm data={data.data} categories={categoriesData.data.items} />
    </ContentLayout>
  );
};

export default ModelsUpdatePage;
