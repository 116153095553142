const { REACT_APP_API_BASE_URL } = process.env;

export const API_BASE_URL = REACT_APP_API_BASE_URL;

// Системы блокировки
export const LOCKING_SYSTEM = [
  { value: 'apple_id', label: 'Apple ID' },
  { value: 'mi_account', label: 'Mi Account' },
  { value: 'security_hub', label: 'Security Hub' },
  { value: 'other', label: 'Прочее' },
];

// Типы локаций
export const LOCATION_TYPE = [
  { value: 'region', label: 'Область' },
  { value: 'city', label: 'Город' },
  { value: 'district', label: 'Район' },
  { value: 'mahalla', label: 'Махалля' },
  { value: 'street', label: 'Улица' },
];

// Уровень локации
export const LOCATION_TYPE_LEVEL = {
  1: 'l_1',
  2: 'l_2',
  3: 'l_3',
  4: 'l_4',
  5: 'l_5',
  6: 'l_6',
  7: 'l_7',
};

// Тип документа удостоверяющего личность
export const PASSPORT_TYPE = [
  { value: 'passport', label: 'Паспорт' },
  { value: 'id', label: 'ID-карта' },
  { value: 'driver_license', label: 'Водительское удостоверение' },
  { value: 'other', label: 'Прочее' },
];

// Валюта
export const CURRENCIES = [
  { value: 'uzs', label: 'Сум' },
  { value: 'usd', label: 'Доллар' },
  { value: 'rub', label: 'Рубль' },
  { value: 'tg', label: 'Тенге' },
  { value: 'other', label: 'Прочее' },
];

// Категории пользователей для смс рассылки
export const CATEGORY_FOR_SMS = [
  { value: 'clients_all', label: 'Все клиенты' },
  { value: 'clients_good', label: 'Хорошие клиенты' },
  { value: 'clients_bad', label: 'Плохие клиенты' },
  { value: 'other', label: 'Прочее' },
];

export const PAYMENT_TYPE = [
  { value: 'cash', label: 'Наличка' },
  { value: 'humo', label: 'Humo' },
  { value: 'uzcard', label: 'UzCard' },
  { value: 'payme', label: 'Payme' },
  { value: 'click', label: 'Click' },
  { value: 'paynet', label: 'Paynet' },
  { value: 'transfer', label: 'Перечислением' },
  { value: 'other', label: 'Прочее' },
];

export const DEFAULT_CURRENCY = 'usd';

export const CURRENCY_SUFFIX = ' у.е.';

export const SMS_LANGUAGES = [
  { value: 'ru', label: 'Русский' },
  { value: 'uz', label: 'Узбекский' },
];
