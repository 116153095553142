import { Box, Paper, Stack } from "@mui/material";
import { ComponentType } from "react";
import { Outlet } from "react-router-dom";
import { withAuthorization } from "../hocs/withAuthorization";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface DashboardProps {}

const Dashboard: ComponentType<DashboardProps> = () => {
  return (
    <Paper
      sx={{
        width: 1,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "background.default",
      }}
    >
      <Sidebar />
      <Stack flex={1}>
        <Header />
        <Box component="main" p={4}>
          <Outlet />
        </Box>
      </Stack>
    </Paper>
  );
};

export default withAuthorization()(Dashboard);
