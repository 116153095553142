import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  baseQuery: rtkBaseQuery('/locations'),
  tagTypes: ['Locations'],
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['Locations'],
    }),
    getNestedLocations: builder.query({
      query: () => '/nested',
      providesTags: ['Locations'],
    }),
    getLocationById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ['Locations'],
    }),
  }),
});

export const { useGetLocationsQuery, useGetNestedLocationsQuery, useGetLocationByIdQuery } = locationsApi;
