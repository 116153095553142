import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetApplicationByIdQuery } from '../../app/services/applicationsApi';
import { useGetClientsQuery } from '../../app/services/clientsApi';
import { useGetSuppliersQuery } from '../../app/services/suppliersApi';
import ContentLayout from '../../components/ContentLayout';
import SalesForm from '../../components/forms/sales/SalesForm';

interface Props {}

const SalesUpdatePage: FC<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useGetApplicationByIdQuery(id);
  const { data: clients } = useGetClientsQuery({ limit: 10000 });
  const { data: suppliers } = useGetSuppliersQuery({ limit: 10000 });
  // const { data: products } = useGetProductsQuery({ limit: 10000 });

  if (!data || !clients || !suppliers) return null;

  return (
    <ContentLayout title={t('sales.update', 'Редактирование продажи')}>
      <SalesForm
        lists={{
          clients: clients.data.items,
          suppliers: suppliers.data.items,
        }}
        data={data.data}
      />
    </ContentLayout>
  );
};

export default SalesUpdatePage;
