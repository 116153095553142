import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetProductCategoryByIdQuery } from "../../../app/services/productCategoriesApi";
import ContentLayout from "../../../components/ContentLayout";
import CategoryForm from "../../../components/forms/category/CategoryForm";

interface Props {}

const CategoriesUpdatePage: FC<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useGetProductCategoryByIdQuery(id);

  if (!data) return null;

  return (
    <ContentLayout title={t("categories.edit", "Редактирование категории")}>
      <CategoryForm data={data.data} />
    </ContentLayout>
  );
};

export default CategoriesUpdatePage;
