import { FormLabel, TextField, TextFieldProps, Typography } from '@mui/material';
import { FC } from 'react';
import RequiredSign from './RequiredSign';

interface Props {
  label?: string | null;
}

const FormField: FC<Props & TextFieldProps> = (props) => {
  const { label, required, ...rest } = props;
  return (
    <FormLabel>
      <Typography variant="body2">
        {label}
        {required && <RequiredSign />}
      </Typography>
      <TextField size="small" fullWidth {...rest} />
    </FormLabel>
  );
};

export default FormField;
