import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const manufacturersApi = createApi({
  reducerPath: 'manufacturersApi',
  baseQuery: rtkBaseQuery('/manufacturers'),
  tagTypes: ['Manufacturers'],
  endpoints: (builder) => ({
    getManufacturers: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['Manufacturers'],
    }),
    getManufacturerById: builder.query({
      query: (id) => `${id}`,
      providesTags: ['Manufacturers'],
    }),
    createManufacturer: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Manufacturers'],
    }),
    updateManufacturer: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'POST',
        body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, { id }) => ['Manufacturers', { type: 'Manufacturers', id }],
    }),
  }),
});

export const {
  useGetManufacturersQuery,
  useGetManufacturerByIdQuery,
  useCreateManufacturerMutation,
  useUpdateManufacturerMutation,
} = manufacturersApi;
