import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useLoginMutation } from "../app/services";
import { getFormikError, hasFormikError } from "../helpers/form";
import useAuth from "../hooks/useAuth";

interface LoginProps {}

const Login: ComponentType<LoginProps> = () => {
  const { t } = useTranslation();
  const { authorize } = useAuth();

  const [login, { isLoading }] = useLoginMutation();

  const formik = useFormik({
    initialValues: {
      login: "admin@taqseet.loc",
      password: "Secret7770",
    },
    validationSchema: yup.object({
      login: yup
        .string()
        .required(t("error.required", "Это поле обязательное")),
      password: yup
        .string()
        .required(t("error.required", "Это поле обязательное")),
    }),
    onSubmit: (values) => {
      login(values)
        .unwrap()
        .then((data) => {
          toast.success(data.message);
          authorize(data);
        })
        .catch((error) => {
          toast.error(error.data.data.error);
        });
    },
  });

  return (
    <Stack height="100vh" width={1} justifyContent="center" alignItems="center">
      <Paper elevation={3} sx={{ width: 1, maxWidth: 500 }}>
        <Stack
          px={4}
          py={3}
          width={1}
          gap={3}
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Typography variant="h5" component="h1">
            {t("auth.title", "Авторизация")}
          </Typography>

          <TextField
            label="Логин"
            {...formik.getFieldProps("login")}
            error={hasFormikError(formik, "login")}
            helperText={getFormikError(formik, "login")}
          />
          <TextField
            label="Пароль"
            type="password"
            {...formik.getFieldProps("password")}
            error={hasFormikError(formik, "password")}
            helperText={getFormikError(formik, "password")}
          />

          <Stack direction="row" justifyContent="space-between" width={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {t("auth.login", "Войти")}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Login;
