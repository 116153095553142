import { t } from "i18next";
import { object, string } from "yup";

export const exchangeRateValidationSchema = object().shape({
  date_at: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
  currency: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
  rate: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
});
