import { Button, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetExchangeRateMutation } from '../../../app/services/settingsApi';
import { CURRENCIES } from '../../../constants/vars';
import { newDate } from '../../../helpers/utils';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { exchangeRateValidationSchema } from './schema';

interface Props {}

const ExchangeRateForm: FC<Props> = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const [save, { isLoading }] = useSetExchangeRateMutation();

  const formik = useFormik({
    initialValues: {
      date_at: newDate(),
      currency: '',
      rate: '',
    },
    validationSchema: exchangeRateValidationSchema,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then((res) => {
          navigate('/dashboard/settings/exchange-rates');
        })
        .catch(console.error);
    },
  });
  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'date_at',
          label: 'Дата',
          type: 'date',
          width: 12,
        },
        {
          name: 'currency',
          label: 'Валюта',
          type: 'select',
          width: 12,
          options: CURRENCIES,
        },
        {
          name: 'rate',
          label: 'Курс',
          type: 'number',
          width: 12,
        },
      ],
    },
  ];

  return (
    <Stack component="form" onSubmit={formik.handleSubmit}>
      {form_sections.map((section) => (
        <Grid container spacing={2} key={section.id}>
          {section.fields.map((field) => (
            <Grid item xs={field.width} key={field.name}>
              <FormikField formik={formik} field={field} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="contained" type="submit" disabled={isLoading || !formik.isValid}>
              {t('action.save', 'Сохранить')}
            </Button>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
};

export default ExchangeRateForm;
