import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetLocationByIdQuery } from "../../app/services/locationsApi";
import { useGetSupplierByIdQuery } from "../../app/services/suppliersApi";
import ContentLayout from "../../components/ContentLayout";
import { renderFullName } from "../../helpers/utils";

interface SuppliersDetailsProps {}

const SuppliersDetails: ComponentType<SuppliersDetailsProps> = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data, isFetching } = useGetSupplierByIdQuery(id);
  const { data: locationData } = useGetLocationByIdQuery(
    data?.data.merchant.location_id || 16,
    {
      skip: !data,
    }
  );

  const user = data?.data.merchant;

  const rows: any[] = [
    {
      id: "last_name",
      label: t("last_name", "Фамилия"),
      value: user?.contact_1_last_name,
    },
    {
      id: "first_name",
      label: t("first_name", "Имя"),
      value: user?.contact_1_first_name,
    },
    {
      id: "second_name",
      label: t("middle_name", "Отчество"),
      value: user?.contact_1_second_name,
    },
    {
      id: "email",
      label: t("email", "Электронная почта"),
      value: user?.contact_1_email,
    },
    {
      id: "phone",
      label: t("phone", "Номер телефона"),
      value: user?.contact_1_phone,
    },
    {
      id: "company_type",
      label: t("company_type", "Тип компании"),
      value:
        user?.company_type === "legal"
          ? t("legalEntity", "Юр. лицо")
          : t("individual", "Физ. лицо"),
    },
    {
      id: "company",
      label: t("company_name", "Название компании"),
      value: user?.company_name,
    },
    {
      id: "address",
      label: t("address", "Адрес"),
      value: [locationData?.data.name, user?.company_legal_address].join(", "),
    },
  ];

  if (!user) return null;

  return (
    <ContentLayout title={renderFullName(user, "contact_1")}>
      {isFetching && "Loading..."}
      <Grid container>
        <Grid item xs={6}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </ContentLayout>
  );
};

export default SuppliersDetails;
