import { FC } from "react";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../../components/ContentLayout";
import CategoryForm from "../../../components/forms/category/CategoryForm";

interface Props {}

const LocationCreatePage: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <ContentLayout title={t("categories.new", "Новая категория")}>
      <CategoryForm />
    </ContentLayout>
  );
};

export default LocationCreatePage;
