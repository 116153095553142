import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
} from '../../../app/services/productCategoriesApi';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { categoryValidationSchema } from './schema';

interface Props {
  data?: any;
}

const CategoryForm: FC<Props> = ({ data }) => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const query = data ? useUpdateProductCategoryMutation : useCreateProductCategoryMutation;

  const [save, { isLoading }] = query();

  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'code',
          label: t('code', 'Код'),
          type: 'text',
          width: 12,
          required: true,
        },
        {
          name: 'name.ru',
          label: t('name_ru', 'Название на русском'),
          type: 'text',
          width: 4,
          required: true,
        },
        {
          name: 'name.uz',
          label: t('name_uz', 'Название на узбекском'),
          type: 'text',
          width: 4,
          required: true,
        },
        {
          name: 'name.en',
          label: t('name_en', 'Название на английском'),
          type: 'text',
          width: 4,
          required: true,
        },
        {
          name: 'description.ru',
          label: t('description_ru', 'Описание на русском'),
          type: 'textarea',
          width: 4,
        },
        {
          name: 'description.uz',
          label: t('description_uz', 'Описание на узбекском'),
          type: 'textarea',
          width: 4,
        },
        {
          name: 'description.en',
          label: t('description_en', 'Описание на английском'),
          type: 'textarea',
          width: 4,
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: {
        ru: '',
        uz: '',
        en: '',
      },
      code: '',
      description: {
        ru: '',
        uz: '',
        en: '',
      },

      ...data,
    },
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then((_) => {
          navigate('/dashboard/settings/categories');
        })
        .catch(console.error);
    },
  });

  return (
    <Stack component={'form'} gap={2} onSubmit={formik.handleSubmit}>
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id} p={2} component={Card}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button
          size="large"
          variant="contained"
          color="inherit"
          onClick={() => navigate('/dashboard/settings/categories')}
        >
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading || !formik.isValid}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default CategoryForm;
