import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateClientMutation, useUpdateClientMutation } from '../../../app/services/clientsApi';
import { PASSPORT_TYPE, SMS_LANGUAGES } from '../../../constants/vars';
import { handleRequestError, objectToFormData } from '../../../helpers/form';
import { newDate } from '../../../helpers/utils';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import RequiredSign from '../../Form/RequiredSign';
import { clientValidationSchema } from './schema';

interface Props {
  data?: Record<string, any>;
}

const ClientForm: FC<Props> = ({ data }) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [passport, setPassport] = useState<File>();
  const [sameAddress, setSameAddress] = useState(
    data?.client.location.id === data?.client.passport_location.id &&
      data?.client.address === data?.client.passport_address
  );

  // console.log('passport', passport);

  const query = data ? useUpdateClientMutation : useCreateClientMutation;
  const [save, { isLoading }] = query();

  const personal_info_fields: Field[] = [
    {
      name: 'client.last_name',
      label: t('last_name', 'Фамилия'),
      type: 'text',
      width: 4,
      required: true,
    },
    {
      name: 'client.first_name',
      label: t('first_name', 'Имя'),
      type: 'text',
      width: 4,
      required: true,
    },
    {
      name: 'client.second_name',
      label: t('middle_name', 'Отчество'),
      type: 'text',
      width: 4,
    },
    {
      name: 'client.birth_date',
      label: t('birthday', 'Дата рождения'),
      type: 'date',
      width: 4,
      required: true,
    },
    {
      name: 'client.phone_1',
      label: t('phoneNumber', 'Номер телефона'),
      type: 'phone',
      width: 4,
      required: true,
    },
    {
      name: 'client.phone_2',
      label: t('phoneNumber2', 'Номер телефона 2'),
      type: 'phone',
      width: 4,
    },
    // {
    //   name: 'address',
    //   label: t('residence_address', 'Адрес проживания'),
    //   type: 'group',
    //   width: 12,
    //   required: true,
    //   fields: [
    //     {
    //       name: 'client.location_id',
    //       type: 'location',
    //       width: 4,
    //     },
    //     {
    //       name: 'client.address',
    //       type: 'text',
    //       width: 8,
    //     },
    //   ],
    // },
    // {
    //   name: 'passport_address',
    //   label: t('registration_address', 'Адрес регистрации'),
    //   type: 'group',
    //   width: 12,
    //   required: true,
    //   fields: [
    //     {
    //       name: 'client.passport_location_id',
    //       type: 'location',
    //       width: 4,
    //     },
    //     {
    //       name: 'client.passport_address',
    //       type: 'text',
    //       width: 8,
    //     },
    //   ],
    // },
    {
      name: 'passport',
      label: t('passport', 'Номер паспорта'),
      type: 'group',
      width: 4,
      required: true,
      fields: [
        {
          name: 'client.passport_series',
          type: 'text',
          width: 4,
        },
        {
          name: 'client.passport_number',
          type: 'text',
          width: 8,
        },
      ],
    },
    {
      name: 'client.pinfl',
      label: t('pinfl', 'ПИНФЛ'),
      type: 'text',
      width: 4,
      required: true,
    },
    {
      name: 'client.place_of_work',
      label: t('place_of_work', 'Место работы'),
      type: 'text',
      width: 4,
    },
  ];

  const form_sections: Section[] = [
    {
      id: 'lead',
      title: t('lead', 'Лид'),
      fields: [
        {
          name: 'client.lead_generation',
          label: t('lead_generation', 'Источник лида'),
          type: 'text',
          width: 6,
        },
      ],
    },
    {
      id: 'guarantor_1',
      title: t('guarantor', 'Поручитель'),
      fields: [
        {
          name: 'client.guarantor_1_last_name',
          label: t('guarantorLastName', 'Фамилия'),
          type: 'text',
          width: 4,
        },
        {
          name: 'client.guarantor_1_first_name',
          label: t('guarantorFirstName', 'Имя'),
          type: 'text',
          width: 4,
        },
        {
          name: 'client.guarantor_1_second_name',
          label: t('guarantorSecondName', 'Отчество'),
          type: 'text',
          width: 4,
        },
        {
          name: 'client.guarantor_1_phone',
          label: t('phoneNumber', 'Номер телефона'),
          type: 'phone',
          width: 4,
        },
        {
          name: 'client.guarantor_1_address',
          label: t('registration_address', 'Адрес регистрации'),
          type: 'text',
          width: 4,
        },
        {
          name: 'client.guarantor_1_social',
          label: t('socials', 'Социальные сети'),
          type: 'text',
          width: 12,
        },
      ],
    },
    {
      id: 'documents',
      title: t('documents', 'Документы'),
      fields: [
        {
          name: 'client.document_type',
          label: t('document_type', 'Тип документа'),
          type: 'select',
          width: 4,
          options: PASSPORT_TYPE,
          required: true,
        },
        {
          name: 'client.passport',
          label: t('client.passportCopy', 'Копия паспорта клиента'),
          type: 'file',
          width: 8,
          // required: true,
          onChange: (e: any) => {
            setPassport(e.currentTarget.files?.[0]);
          },
        },
      ],
    },
    {
      id: 'params',
      title: t('params', 'Параметры'),
      fields: [
        {
          label: null,
          name: 'params',
          type: 'params',
          width: 8,
        },
      ],
    },
    {
      id: 'note',
      title: t('note', 'Примечание'),
      fields: [
        {
          name: 'client.note',
          label: t('note', 'Примечание'),
          type: 'textarea',
          width: 12,
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      language_code: 'uz',
      ...data,
      client: {
        last_name: '',
        first_name: '',
        second_name: '',
        birth_date: newDate(),
        phone_1: '',
        phone_2: '',
        location_id: data?.client.location.id || undefined,
        address: '',
        passport_location_id: data?.client.passport_location.id || undefined,
        passport_address: '',
        passport_series: '',
        passport_number: '',
        document_type: '',
        passport: undefined,
        pinfl: '',
        place_of_work: '',
        lead_generation: '',
        guarantor_1_first_name: '',
        note: '',
        params: [],
        ...data?.client,
      },
    },
    validationSchema: clientValidationSchema,
    onSubmit: (values) => {
      const body = objectToFormData(values);
      passport && body.append('client[passport]', passport);
      data && body.append('_method', 'PUT');

      save({ id: data?.id, body: passport ? body : values })
        .unwrap()
        .then(() => {
          toast.success(t('client.save.success', 'Клиент успешно сохранен'));
          navigate('/dashboard/clients');
        })
        .catch((error) => handleRequestError(error, formik));
    },
  });

  useEffect(() => {
    if (sameAddress) {
      formik.setFieldValue('client.passport_location_id', formik.values.client.location_id);
      formik.setFieldValue('client.passport_address', formik.values.client.address);
    }
  }, [formik.values.client.location_id, formik.values.client.address, sameAddress]);

  const handleLocationCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameAddress(event.target.checked);
  };

  return (
    <Stack component={'form'} gap={2} onSubmit={formik.handleSubmit}>
      <Stack gap={2} key="personal_info">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} key="language_code">
            <FormLabel>
              <Typography variant="body2">
                {t('sms_preferred_language', 'Предпочитаемый язык для СМС')}
                <RequiredSign />
              </Typography>
              <TextField select {...formik.getFieldProps('language_code')} fullWidth>
                {SMS_LANGUAGES.map((lang) => (
                  <MenuItem key={lang.value} value={lang.value}>
                    {lang.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormLabel>
          </Grid>
        </Grid>

        <Typography variant="h6">{t('personal_info', 'Персональная информация')}</Typography>
        <Grid container spacing={2}>
          {personal_info_fields.map((field) => (
            <Grid item xs={12} md={field.width} key={field.name}>
              <FormikField formik={formik} field={field} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormikField
              formik={formik}
              field={{
                name: 'address',
                label: t('residence_address', 'Адрес проживания'),
                type: 'group',
                required: true,
                fields: [
                  {
                    name: 'client.location_id',
                    type: 'location',
                    width: 4,
                  },
                  {
                    name: 'client.address',
                    type: 'text',
                    width: 8,
                  },
                ],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              <FormControlLabel
                control={<Checkbox defaultChecked checked={sameAddress} onChange={handleLocationCheckbox} />}
                label="Адрес проживания является адресом регистрации"
              />
            </FormLabel>
            {!sameAddress && (
              <FormikField
                formik={formik}
                field={{
                  name: 'address',
                  label: t('registration_address', 'Адрес регистрации'),
                  type: 'group',
                  required: true,
                  fields: [
                    {
                      name: 'client.passport_location_id',
                      type: 'location',
                      width: 4,
                    },
                    {
                      name: 'client.passport_address',
                      type: 'text',
                      width: 8,
                    },
                  ],
                }}
              />
            )}
          </Grid>
        </Grid>
      </Stack>
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id} gap={2}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}

      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button size="large" variant="contained" color="inherit" onClick={() => navigate('/dashboard/clients')}>
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default ClientForm;
