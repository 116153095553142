import { FC } from "react";
import ContentLayout from "../../../components/ContentLayout";
import ExchangeRateForm from "../../../components/forms/exchangeRate/ExchangeRateForm";
import useLocale from "../../../hooks/useLocale";

interface Props {}

const ExchangeRateCreatePage: FC<Props> = () => {
  const { t } = useLocale();

  return (
    <ContentLayout title={t("exchangeRates.new", "Новый курс валют")}>
      <ExchangeRateForm />
    </ContentLayout>
  );
};

export default ExchangeRateCreatePage;
