import { t } from 'i18next';
import { object, string } from 'yup';

export const paymentValidationSchema = object().shape({
  payment_at: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  amount: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  currency: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  type: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  note: string().nullable(),
});
