import { FC } from 'react';
import { useGetApplicationPaymentsQuery } from '../../app/services/applicationsApi';
import LayoutList from '../../components/LayoutList';
import { CURRENCY_SUFFIX, PAYMENT_TYPE } from '../../constants/vars';
import { formatNumber, renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const PaymentsListPage: FC<Props> = () => {
  const { t, locale } = useLocale();

  const cols = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'client',
      label: t('client', 'Клиент'),
      render: (row: any) => renderFullName(row?.client),
    },
    {
      id: 'product',
      label: t('product', 'Товар'),
      render: (row: any) => row?.application.products.map((product: any) => product.title[locale]).join(', '),
    },
    {
      id: 'payment_at',
      label: t('paymentDate', 'Дата платежа'),
      render: (row: any) => new Date(row.payment_at).toLocaleDateString('ru'),
    },
    {
      id: 'amount',
      label: t('paymentAmount', 'Сумма платежа') + ',' + CURRENCY_SUFFIX,
      render: (row: any) => formatNumber(row.amount),
    },
    {
      id: 'type',
      label: t('paymentMethod', 'Способ платежа'),
      render: (row: any) => PAYMENT_TYPE.find((item) => item.value === row.type)?.label,
    },
  ];
  return (
    <LayoutList
      title={t('payments', 'Платежи')}
      cols={cols}
      query={useGetApplicationPaymentsQuery}
      filter="full"
      noAdd
      noDetails
      noEdit
    />
  );
};

export default PaymentsListPage;
