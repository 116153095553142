import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApplicationByIdQuery } from '../../app/services/applicationsApi';
import ContentLayout from '../../components/ContentLayout';
import { CURRENCY_SUFFIX } from '../../constants/vars';
import { formatNumber, renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const DebtorDetailsPage: FC<Props> = () => {
  const { locale } = useLocale();
  const { id } = useParams();
  const { data } = useGetApplicationByIdQuery(id);

  if (!data) return null;

  const application = data.data;

  const rows = [
    {
      id: 'customer',
      label: 'Клиент',
      value: renderFullName(application?.client),
    },
    {
      id: 'supplier',
      label: 'Поставщик',
      value: renderFullName(application?.merchant, 'contact_1'),
    },
    {
      id: 'product',
      label: 'Товар',
      value: (
        <Stack>
          {application.products.map((product: any) => (
            <Typography>{product.title[locale]}</Typography>
          ))}
        </Stack>
      ),
    },
    {
      id: 'sale_date',
      label: 'Дата продажи',
      value: application?.created_at,
    },
    {
      id: 'sale_amount',
      label: 'Сумма продажи',
      value: formatNumber(application?.price) + CURRENCY_SUFFIX,
    },
    {
      id: 'sale_prepayment',
      label: 'Сумма предоплаты',
      value: formatNumber(application?.prepayment) + CURRENCY_SUFFIX,
    },
    {
      id: 'term',
      label: 'Срок рассрочки',
      value: application?.loan_term + ' месяцев',
    },
    {
      id: 'credit_amount',
      label: 'Сумма рассрочки',
      value: formatNumber(application?.loan_amount) + CURRENCY_SUFFIX,
    },
    {
      id: 'monthly_payment',
      label: 'Ежемесячный платеж',
      value: formatNumber(application?.loan_monthly_payment) + CURRENCY_SUFFIX,
    },
    {
      id: 'box_given',
      label: 'Коробка выдана',
      value: application?.is_box_released,
    },
    {
      id: 'has_protection',
      label: 'Защита',
      value: application?.is_protection_installed,
    },
    {
      id: 'protection_login',
      label: 'Логин защиты',
      value: application?.protection_login,
      hide: application?.is_protection_installed !== 0 || !application?.has_protection,
    },
  ];

  return (
    <ContentLayout title={'Детали продажи'}>
      <Stack gap={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" mb={2}>
              {t('general_info', 'Общая информация')}
            </Typography>
            <Table component={Paper}>
              <TableBody>
                {rows.map(
                  (row: any) =>
                    !row.hide && (
                      <TableRow>
                        <TableCell>{row.label}</TableCell>
                        <TableCell align="right">
                          {row.id === 'params'
                            ? row.value &&
                              typeof row.value === 'string' &&
                              row.value.map((param: any) => param.name + ': ' + param.value)
                            : row.value}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" mb={2}>
              {/* График погашений */}
              {t('payment_schedule', 'График погашений')}
            </Typography>

            <Table component={Paper} sx={{ '& .MuiTableCell-root': { textAlign: 'center' } }}>
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>Остаток,{CURRENCY_SUFFIX}</TableCell>
                  <TableCell>Оплата,{CURRENCY_SUFFIX}</TableCell>
                  <TableCell>Дата оплаты</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {application.schedule.map((payment: any, index: number) => (
                  <TableRow>
                    <TableCell>{payment.number}</TableCell>
                    <TableCell>{formatNumber(payment.balance)}</TableCell>
                    <TableCell>{formatNumber(payment.payment_amount)}</TableCell>
                    <TableCell>{new Date(payment.payment_at).toLocaleDateString('ru')}</TableCell>
                    <TableCell>
                      <Button variant="contained">Оплата</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Stack>
    </ContentLayout>
  );
};

export default DebtorDetailsPage;
