import { Autocomplete, Box, FormLabel, Grid, InputAdornment, ListItem, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { getIn } from 'formik';
import { FC } from 'react';
import InputMask from 'react-input-mask';
import FormField from './FormField';
import LocationSelector from './LocationSelector';
import ParamsFields from './ParamsFields';
import ProductsSelector from './ProductsSelector';
import RequiredSign from './RequiredSign';

interface Props {
  formik: any;
  field: FormikFieldProps;
}

// const filter = createFilterOptions();

const FormikField: FC<Props> = ({ formik, field }) => {
  let Input;
  const helperText = getIn(formik.errors, field.name);
  const error = getIn(formik.touched, field.name) && Boolean(helperText);

  switch (field.type) {
    case 'phone':
      Input = (
        <InputMask
          mask="+\9\9\8 99 999 99 99"
          maskChar=" "
          value={getIn(formik.values, field.name)}
          onChange={(e: any) => formik.setFieldValue(field.name, e.target.value.replace(/\s/g, ''))}
        >
          <FormField {...field} />
        </InputMask>
      );
      break;
    case 'products_selector':
      Input = (
        <ProductsSelector
          // value={field.value}
          // onChange={(products: RecordObject[]) => formik.setFieldValue(field.name, products)}
          items={getIn(formik.values, field.name)}
          handleChange={(items: any) => formik.setFieldValue(field.name, items)}
          required={field.required}
        />
      );
      break;
    case 'location':
      Input = (
        <LocationSelector
          {...formik.getFieldProps(field.name)}
          onChange={(val: any) => {
            formik.setFieldValue(field.name, val);
          }}
          error={error}
          helperText={error && helperText}
          required={field.required}
        />
      );
      break;
    case 'select':
      Input = (
        <Autocomplete
          renderInput={(params) => {
            return (
              <FormLabel>
                <Typography variant="body2">
                  {field.label}
                  {field.required && <RequiredSign />}
                </Typography>
                <FormField {...params} error={error} helperText={error && helperText} />
              </FormLabel>
            );
          }}
          onReset={() => ({ label: '', value: '' })}
          defaultValue={field.options?.find(
            ({ value }: FormikFieldOptionProps) => value === getIn(formik.values, field.name)
          )}
          // value={getIn(formik.values, field.name) || null}
          onChange={(_, option: any) => formik.setFieldValue(field.name, option ? option.value : null)}
          options={field.options || []}
          renderOption={(props, option, state) => (
            <ListItem {...props}>
              <Typography>{option.label}</Typography>
            </ListItem>
          )}
          // isOptionEqualToValue={(option: FormikFieldOptionProps, value: any) => option.value === value}
          getOptionDisabled={(option: any) => option.children?.length > 0}
        />
      );
      break;
    case 'date':
      const d = getIn(formik.values, field.name);
      const date = d ? new Date(d) : new Date();

      Input = (
        <FormLabel>
          <Typography variant="body2">
            {field.label}
            {field.required && <RequiredSign />}
          </Typography>
          <DatePicker
            // format="DD.MM.YYYY"
            {...formik.getFieldProps(field.name)}
            value={date}
            onChange={(val: any) => {
              // console.log("val", val);
              formik.setFieldValue(
                field.name,
                format(new Date(val), 'yyyy-MM-dd')
                // new Date(val).toLocaleDateString("ru")
              );
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
                error,
                helperText: error && helperText,
              },
            }}
          />
        </FormLabel>
      );
      break;
    case 'group':
      const fields = field.fields
        ? field.fields.map((field) => (
            <Grid item xs={12} md={field.width} key={field.name}>
              <FormikField formik={formik} field={field} />
            </Grid>
          ))
        : [];
      Input = (
        <>
          <FormLabel>
            <Typography variant="body2">
              {field.label}
              {field.required && <RequiredSign />}
            </Typography>
          </FormLabel>
          <Grid container spacing={2}>
            {fields}
          </Grid>
        </>
      );
      break;
    case 'params':
      Input = (
        <ParamsFields
          name={field.name}
          data={getIn(formik.values, field.name)}
          onChange={(val: any) => formik.setFieldValue(field.name, val)}
        />
      );
      break;
    default:
      Input = (
        <FormField
          multiline={field.type === 'textarea'}
          rows={3}
          value={getIn(formik.values, field.name)}
          onChange={(e: any) => {
            formik.setFieldValue(field.name, field.type === 'file' ? e.currentTarget.files?.[0] : e.target.value);
          }}
          {...formik.getFieldProps(field.name)}
          InputLabelProps={['file', 'date'].includes(field.type) ? { shrink: true } : {}}
          error={error}
          helperText={error && helperText}
          InputProps={{
            readOnly: field.readOnly,
            endAdornment: field.suffix && <InputAdornment position="end">{field.suffix}</InputAdornment>,
          }}
          {...field}
        />
      );
  }
  return <Box>{Input}</Box>;
};

export default FormikField;
