import { Box } from "@mui/material";
import { FC } from "react";

interface Props {}

const RequiredSign: FC<Props> = () => {
  return (
    <Box component="span" color="error.main">
      {" "}
      *
    </Box>
  );
};

export default RequiredSign;
