import { t } from 'i18next';
import { array, mixed, object, string } from 'yup';

export const clientValidationSchema = object().shape({
  client: object().shape({
    last_name: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    first_name: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    second_name: string().nullable(),
    birth_date: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    phone_1: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    phone_2: string().nullable(),
    location_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    address: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    passport_location_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    passport_address: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    passport_series: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    passport_number: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    document_type: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    passport: mixed().nullable(),
    inn: string().nullable(),
    pinfl: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    work_place: string().nullable(),
    lead_source: string().nullable(),
    guarantor_1_first_name: string().nullable(),
    note: string().nullable(),
    params: array(),
  }),
});
