import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetProductCategoriesQuery } from "../../../app/services/productCategoriesApi";
import ContentLayout from "../../../components/ContentLayout";
import ModelsForm from "../../../components/forms/model/ModelsForm";

interface Props {}

const ModelsCreatePage: FC<Props> = () => {
  const { t } = useTranslation();
  const { data: categoriesData } = useGetProductCategoriesQuery({
    limit: 999,
    sort: "id",
  });

  if (!categoriesData) return null;

  return (
    <ContentLayout title={t("models.new", "Новая модель")}>
      <ModelsForm categories={categoriesData.data.items} />
    </ContentLayout>
  );
};

export default ModelsCreatePage;
