import { ArrowDropDown } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NavigationItem from './NavigationItem';

const NavigationItemExpandable: ComponentType<NavItemProps> = ({
  titleKey,
  title,
  children,
  icon: Icon,
  sidebarOpen,
  path,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const openItem = location.pathname.startsWith('' + path);
  const [open, setOpen] = useState(openItem);

  const toggleOpen = () => setOpen(!open);

  const label = t(titleKey, title);
  return (
    <>
      <Tooltip title={t(titleKey, title)} placement="right" arrow disableHoverListener={sidebarOpen}>
        <ListItemButton
          onClick={toggleOpen}
          sx={{
            minHeight: 48,
            justifyContent: sidebarOpen ? 'initial' : 'center',
            px: sidebarOpen ? 2.5 : 1.5,
            transition: '.3s',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sidebarOpen ? 2 : 'auto',
              justifyContent: 'center',
              color: 'inherit',
            }}
          >
            {Icon && <Icon />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={label}
            sx={{
              opacity: sidebarOpen ? 1 : 0,
              whiteSpace: 'nowrap',
              typography: 'body2',
            }}
          />
          <ListItemIcon
            sx={{
              color: 'inherit',
              minWidth: 0,
              transform: `rotate(${open ? '180deg' : '0'})`,
              mr: -1,
              display: sidebarOpen ? 'inline-flex' : 'none',
            }}
          >
            <ArrowDropDown />
          </ListItemIcon>
        </ListItemButton>
      </Tooltip>
      {children?.length && (
        <Collapse
          in={open}
          sx={
            {
              // backgroundColor: "primary.light",
            }
          }
        >
          {children.map((item) => (
            <NavigationItem key={item.titleKey} {...item} sidebarOpen={sidebarOpen} icon={null} />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default NavigationItemExpandable;
