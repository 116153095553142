export const newDate = (date?: string) => {
  const d = date ? new Date(date) : new Date();
  if (isNaN(d.getTime())) {
    console.error('Invalid date : ', date);
    return null;
  }
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const urlWithSearchParams = (url: string, params: Record<string, any>) => {
  const urlParts = url.split('?');
  const urlParams = new URLSearchParams(urlParts[1]);
  const newUrlParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      acc.set(key, value);
    }
    return acc;
  }, urlParams);

  return `${urlParts[0]}?${newUrlParams.toString()}`;
};

export const renderFullName = (user: Record<string, any> | undefined, prefix?: string) => {
  if (!user) return '';

  const object = prefix ? extractFieldsFromObject(user, prefix) : user;

  const { last_name, first_name, second_name } = object;
  const fullName = [last_name, first_name, second_name].join(' ');

  return fullName.trim();
};

export const extractFieldsFromObject = (obj: Record<string, any>, prefix: string) => {
  const prefixLength = prefix.length + 1;
  const fields = Object.entries(obj).reduce((acc: any, [key, value]: any) => {
    if (key.startsWith(prefix)) {
      acc[key.slice(prefixLength)] = value;
    }
    return acc;
  }, {});

  return fields;
};

export const formatNumber = (number: number | string) => {
  const num = Number(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString('ru');
};

export const getLabelFromOptions = (options: { label: string; value: any }[], value: any) => {
  const option = options.find((option) => option.value === value);
  return option?.label || value;
};

// get search params from url and return as object
export const getSearchParams = () => {
  const query: any = new URLSearchParams(window.location.search);
  const params: any = {};
  for (const [key, value] of query.entries()) {
    params[key] = value;
  }
  return params;
};

export const convertLocalizedDate = (date: string | null) => {
  if (!date) return null;
  const [day, month, year] = date.split('.');
  return new Date(`${year}-${month}-${day}`);
};
