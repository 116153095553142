import { Download } from '@mui/icons-material';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetClientByIdQuery } from '../../app/services/clientsApi';
import ContentLayout from '../../components/ContentLayout';
import { renderFullName } from '../../helpers/utils';
import { PASSPORT_TYPE } from './../../constants/vars';

interface Props {}

const ClientDetailsPage: FC<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  // const [client, setClient] = useState<RecordObject>();
  const { data } = useGetClientByIdQuery(id);

  if (!data) return null;

  const { client } = data.data;

  // useUpdateEffect(() => {
  //   if (data) {
  //     setClient(data.data.client);
  //   }
  // }, [data]);

  const rows = [
    {
      id: 'birth_date',
      label: t('birthday', 'Дата рождения'),
      value: new Date(client.birth_date).toLocaleDateString('ru'),
    },
    {
      id: 'phone',
      label: t('phone', 'Номер телефона'),
      value: client.phone_1,
    },
    {
      id: 'phone2',
      label: t('phone2', 'Номер телефона 2'),
      value: client.phone_2,
    },
    {
      id: 'registrationAddress',
      label: t('registrationAddress', 'Прописка'),
      value: [client.passport_location.name, client.passport_address].join(', '),
    },
    {
      id: 'address',
      label: t('residence_address', 'Адрес проживания'),
      value: [client.location.name, client.address].join(', '),
    },
    {
      id: 'passport',
      label: t('passport', 'Паспорт'),
      value: client.passport_series + client.passport_number,
    },
    {
      id: 'document',
      label: t('document', 'Документ'),
      value: client.attachments[0] && (
        <Button startIcon={<Download />} size="small" download href={client.attachments[0].url} target="_blank">
          {PASSPORT_TYPE.find((pt) => pt.value === client.attachments[0].document_type)?.label}
        </Button>
      ),
    },
    {
      id: 'pinfl',
      label: t('pinfl', 'ПИНФЛ'),
      value: client.pinfl,
    },
    {
      id: 'place_of_work',
      label: t('workPlace', 'Место работы'),
      value: client.place_of_work,
    },
    {
      id: 'leadGeneration',
      label: t('leadGeneration', 'Генерация лида'),
      value: client.lead_generation,
    },
    {
      id: 'guarantor',
      label: t('guarantor', 'Поручитель'),
      value: renderFullName(client, 'guarantor_1'),
    },
    {
      id: 'guarantor.phone',
      label: t('guarantor.phone', 'Номер телефона'),
      value: client.guarantor_1_phone,
    },
    {
      id: 'guarantor.address',
      label: t('guarantor.address', 'Адрес поручителя'),
      value: client.guarantor_1_address,
    },
    {
      id: 'guarantor.socialNetwork',
      label: t('guarantor.socialNetwork', 'Соц сеть поручителя'),
      value: client.guarantor_1_social,
    },
    {
      id: 'note',
      label: t('note', 'Примечание'),
      value: client.note,
    },
  ];

  const params = client.params;

  return (
    <ContentLayout title={renderFullName(client)}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" mb={2}>
            {t('general_info', 'Общая информация')}
          </Typography>
          <Table component={Paper}>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {params?.length > 0 && (
          <Grid item xs={6}>
            <Typography variant="h6" mb={2}>
              {t('params', 'Параметры')}
            </Typography>
            <Table component={Paper}>
              <TableBody>
                {params.map((param: any) => (
                  <TableRow>
                    <TableCell>{param.label}</TableCell>
                    <TableCell align="right">{param.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </ContentLayout>
  );
};

export default ClientDetailsPage;
