import { FC } from "react";
import { useGetProductCategoriesQuery } from "../../../app/services/productCategoriesApi";
import LayoutList from "../../../components/LayoutList";
import useLocale from "../../../hooks/useLocale";

interface Props {}

const CategoriesListPage: FC<Props> = () => {
  const { t, locale } = useLocale();

  const cols = [
    { id: "id", label: "ID", minWidth: 50 },
    {
      id: "name",
      label: t("name", "Название"),
      render: (row: any) => row.name[locale],
    },
    { id: "code", label: t("code", "Код"), minWidth: 100 },
    {
      id: "description",
      label: t("description", "Описание"),
      render: (row: any) => row?.description?.[locale],
    },
  ];

  return (
    <LayoutList
      title={t("categories", "Категории")}
      cols={cols}
      query={useGetProductCategoriesQuery}
      noDetails
      filter="none"
    />
  );
};

export default CategoriesListPage;
