import { createTheme } from "@mui/material/styles";
import components from "./components";
import typography from "./typography";

const theme = createTheme({
  components,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  typography,
  palette: {
    mode: "dark",
    primary: {
      main: "#252526",
    },
  },
});

export default theme;
