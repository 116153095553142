import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { rtkBaseQuery } from '../../helpers/rtkquery';

export const suppliersApi = createApi({
  reducerPath: 'suppliersApi',
  baseQuery: rtkBaseQuery('/merchants'),
  tagTypes: ['Suppliers'],
  endpoints: (builder) => ({
    getSuppliers: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['Suppliers'],
    }),
    getSupplierById: builder.query({
      query: (id) => `${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Suppliers', id }],
    }),
    createSupplier: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Suppliers'],
    }),
    updateSupplier: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'POST',
        body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, { id }) => ['Suppliers', { type: 'Suppliers', id }],
    }),
    deleteSupplier: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'POST',
        body: { _method: 'DELETE' },
      }),
      invalidatesTags: ['Suppliers'],
    }),

    getSuppliersWithStock: builder.query({
      query: (params) => ({ url: 'with-stock', params }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useGetSupplierByIdQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useGetSuppliersWithStockQuery,
} = suppliersApi;
