import { Box, Stack } from '@mui/material';
import { ComponentType } from 'react';
import { useGetProductsQuery } from '../../app/services/productsApi';
import LayoutList from '../../components/LayoutList';
import { CURRENCY_SUFFIX } from '../../constants/vars';
import { renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const ProductListPage: ComponentType<Props> = () => {
  const { t, locale } = useLocale();

  const columns = [
    { id: 'id', label: 'ID', minWidth: 50 },
    {
      id: 'info',
      label: t('goods.brandModelSerial', 'Марка, модель, серийный номер'),
      render: (row: any) => (
        <Stack>
          <Box>{row.title[locale]}</Box>
          <Box>{row.serial_id_1}</Box>
        </Stack>
      ),
    },
    {
      id: 'is_sold',
      label: t('sold', 'Продано'),
      render: (row: any) => (row.is_sold ? 'Да' : 'Нет'),
    },
    {
      id: 'supplier',
      label: t('supplier', 'Поставщик'),
      render: (row: any) => renderFullName(row.merchant.merchant, 'contact_1'),
    },
    {
      id: 'price',
      label: t('price', 'Цена'),
      render: (row: any) => row.price_income + CURRENCY_SUFFIX,
    },
    {
      id: 'date_income',
      label: t('date_income', 'Дата прихода'),
      render: (row: any) => new Date(row.date_income).toLocaleDateString('ru'),
    },
    // {
    //   id: "note",
    //   label: t("note", "Примечание"),
    // },
  ];

  return (
    <LayoutList
      title={t('products', 'Товары')}
      cols={columns}
      query={useGetProductsQuery}
      queryParams={{ is_sold: 'all' }}
    />
  );
};

export default ProductListPage;
