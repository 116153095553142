import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetClientsQuery } from '../../app/services/clientsApi';
import { useGetSuppliersQuery } from '../../app/services/suppliersApi';
import ContentLayout from '../../components/ContentLayout';
import SalesForm from '../../components/forms/sales/SalesForm';

interface Props {}

const SalesCreatePage: FC<Props> = () => {
  const { t } = useTranslation();

  const { data: clients } = useGetClientsQuery({ limit: 10000 });
  const { data: suppliers } = useGetSuppliersQuery({ limit: 10000 });

  if (!clients || !suppliers) return null;

  return (
    <ContentLayout title={t('sales.new', 'Новая продажа')}>
      <SalesForm
        lists={{
          clients: clients.data.items,
          suppliers: suppliers.data.items,
        }}
      />
    </ContentLayout>
  );
};

export default SalesCreatePage;
