import { Box } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./app/router";

function App() {
  return (
    <Box bgcolor="#fafafb">
      <RouterProvider router={router} />
    </Box>
  );
}

export default App;
