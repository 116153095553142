import {
  AccountBox,
  AdminPanelSettings,
  CalendarMonth,
  Category,
  Description,
  Devices,
  MonetizationOn,
  People,
  Settings,
  Sms,
  Store,
} from '@mui/icons-material';
import { List, ListItem } from '@mui/material';
import { ComponentType } from 'react';
import NavigationItem from './NavigationItem';
import NavigationItemExpandable from './NavigationItemExpandable';

const navLinks: NavLinkProps[] = [
  {
    titleKey: 'nav.suppliers',
    title: 'Поставщики',
    icon: Store,
    path: '/dashboard/suppliers',
  },
  {
    titleKey: 'nav.goods',
    title: 'Товары',
    icon: Devices,
    path: '/dashboard/products',
  },
  {
    titleKey: 'nav.customers',
    title: 'Клиенты',
    icon: People,
    path: '/dashboard/clients',
  },
  {
    titleKey: 'nav.sales',
    title: 'Продажи',
    icon: MonetizationOn,
    path: '/dashboard/sales',
  },
  {
    titleKey: 'nav.payments',
    title: 'Платежи',
    icon: CalendarMonth,
    path: '/dashboard/payments',
  },
  {
    titleKey: 'nav.debtors',
    title: 'Должники',
    icon: AccountBox,
    path: '/dashboard/debtors',
  },
  {
    titleKey: 'nav.acts',
    title: 'Акты',
    icon: Description,
    path: '/dashboard/acts',
  },
  {
    titleKey: 'nav.sms',
    title: 'SMS',
    icon: Sms,
    path: '/dashboard/sms',
  },
  {
    titleKey: 'nav.settings',
    title: 'Настройки',
    icon: Settings,
    path: '/dashboard/settings',
    children: [
      {
        titleKey: 'categories',
        title: 'Категории',
        icon: Category,
        path: '/dashboard/settings/categories',
      },
      {
        titleKey: 'manufacturers',
        title: 'Производители',
        icon: AdminPanelSettings,
        path: '/dashboard/settings/manufacturers',
      },
      {
        titleKey: 'models',
        title: 'Модели',
        icon: AdminPanelSettings,
        path: '/dashboard/settings/models',
      },
      {
        titleKey: 'locations',
        title: 'Местоположения',
        icon: AdminPanelSettings,
        path: '/dashboard/settings/locations',
      },
      // {
      //   titleKey: "nav.referenceBooks",
      //   title: "Справочники",
      //   icon: Book,
      //   path: "/dashboard/settings/reference-books",
      // },
      // {
      //   titleKey: "nav.userRoles",
      //   title: "Роли",
      //   icon: AdminPanelSettings,
      //   path: "/dashboard/settings/user-roles",
      // },
      // {
      //   titleKey: "nav.users",
      //   title: "Пользователи",
      //   icon: PeopleAlt,
      //   path: "/dashboard/settings/users",
      // },
      {
        titleKey: 'nav.exchangeRates',
        title: 'Курсы валют',
        icon: AdminPanelSettings,
        path: '/dashboard/settings/exchange-rates',
      },
    ],
  },
];

const Navigation: ComponentType<NavigationProps> = ({ sidebarOpen }) => {
  // t('nav.suppliers', 'Поставщики')
  // t('nav.goods', 'Товары')
  // t('nav.customers', 'Клиенты')
  // t('nav.sales', 'Продажи')
  // t('nav.payments', 'Платежи')
  // t('nav.debtors', 'Должники')
  // t('nav.acts', 'Акты')
  // t('nav.sms', 'SMS')
  // t('nav.settings', 'Настройки')
  // t('nav.categories', 'Категории')
  // t('nav.referenceBooks', 'Справочники')
  // t('nav.userRoles', 'Роли')
  // t('nav.users', 'Пользователи')

  return (
    <List>
      {navLinks.map((item, index) => (
        <ListItem key={index} disablePadding sx={{ display: 'block' }}>
          {item.children ? (
            <NavigationItemExpandable {...item} sidebarOpen={sidebarOpen} />
          ) : (
            <NavigationItem {...item} sidebarOpen={sidebarOpen} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default Navigation;
