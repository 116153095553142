import { t } from 'i18next';
import { object, string } from 'yup';

export const modelValidationSchema = object().shape({
  product_category_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  name: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  code: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  manufacturer_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  description: string().nullable(),
});
