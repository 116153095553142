import { ComponentType } from "react";
import Login from "./Login";

interface HomeProps {}

const Home: ComponentType<HomeProps> = () => {
  return <Login />;
};

export default Home;
