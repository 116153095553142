import { ComponentType } from "react";

interface UsersProps {}

const Users: ComponentType<UsersProps> = () => {
  return (
    <div>
      <h1>Users</h1>
    </div>
  );
};

export default Users;
