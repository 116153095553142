import { createTheme } from "@mui/material/styles";
import components from "./components";
import typography from "./typography";

const theme = createTheme({
  // ...themeOptions,
  components,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  typography,
  palette: {
    mode: "light",
    background: {
      default: "#eee",
      paper: "#fff",
    },
  },
});

export default theme;
