import { useEffect, useRef } from 'react';

const useUpdateEffect = function (effectCallback: () => void, deps: any[] = []) {
  const isFirstMount = useRef(false);

  useEffect(() => {
    return () => {
      isFirstMount.current = false;
    };
  }, []);

  useEffect(() => {
    // Не выполняйте effectcallback для первого раза
    if (!isFirstMount.current) {
      isFirstMount.current = true;
    } else {
      return effectCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useUpdateEffect;
