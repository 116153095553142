import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Home from "../pages/Home";

import Welcome from "../pages/Welcome";
import ActDetailsPage from "../pages/acts/ActDetailsPage";
import ActListPage from "../pages/acts/ActListPage";
import {
  ClientCreatePage,
  ClientDetailsPage,
  ClientListPage,
  ClientUpdatePage,
} from "../pages/clients";
import { DebtorDetailsPage, DebtorListPage } from "../pages/debtors";
import { Payments } from "../pages/payments";
import Products from "../pages/products";
import SalesCreatePage from "../pages/sales/SalesCreatePage";
import SalesDetailsPage from "../pages/sales/SalesDetailsPage";
import SalesListPage from "../pages/sales/SalesListPage";
import SalesUpdatePage from "../pages/sales/SalesUpdatePage";
import { References, Roles, Users } from "../pages/settings";
import {
  CategoriesCreatePage,
  CategoriesListPage,
  CategoriesUpdatePage,
} from "../pages/settings/categories";
import ExchangeRateCreatePage from "../pages/settings/exchange-rates/ExchangeRateCreatePage";
import ExchangeRateListPage from "../pages/settings/exchange-rates/ExchangeRateListPage";
import {
  LocationCreatePage,
  LocationListPage,
  LocationUpdatePage,
} from "../pages/settings/locations";
import {
  ManufacturersCreatePage,
  ManufacturersListPage,
  ManufacturersUpdatePage,
} from "../pages/settings/manufacturers";
import {
  ModelsCreatePage,
  ModelsListPage,
  ModelsUpdatePage,
} from "../pages/settings/models";
import SmsCreatePage from "../pages/sms/SmsCreatePage";
import SmsListPage from "../pages/sms/SmsListPage";
import Suppliers from "../pages/suppliers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <Welcome />,
      },
      {
        path: "suppliers",
        children: [
          { index: true, element: <Suppliers.List /> },
          { path: "create", element: <Suppliers.Create /> },
          { path: ":id", element: <Suppliers.Details /> },
          { path: ":id/update", element: <Suppliers.Update /> },
        ],
      },
      {
        path: "products",
        children: [
          { index: true, element: <Products.List /> },
          { path: "create", element: <Products.Create /> },
          { path: ":id", element: <Products.Details /> },
          { path: ":id/update", element: <Products.Update /> },
        ],
      },
      {
        path: "clients",
        children: [
          { index: true, element: <ClientListPage /> },
          { path: "create", element: <ClientCreatePage /> },
          { path: ":id", element: <ClientDetailsPage /> },
          { path: ":id/update", element: <ClientUpdatePage /> },
        ],
      },
      {
        path: "sales",
        children: [
          { index: true, element: <SalesListPage /> },
          { path: "create", element: <SalesCreatePage /> },
          { path: ":id", element: <SalesDetailsPage /> },
          { path: ":id/update", element: <SalesUpdatePage /> },
        ],
      },
      {
        path: "payments",
        element: <Payments />,
      },
      {
        path: "debtors",
        children: [
          { index: true, element: <DebtorListPage /> },
          { path: ":id", element: <DebtorDetailsPage /> },
        ],
      },
      {
        path: "acts",
        children: [
          { index: true, element: <ActListPage /> },
          { path: ":id", element: <ActDetailsPage /> },
        ],
      },
      {
        path: "sms",
        children: [
          { index: true, element: <SmsListPage /> },
          { path: "create", element: <SmsCreatePage /> },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "categories",
            children: [
              { index: true, element: <CategoriesListPage /> },
              { path: "create", element: <CategoriesCreatePage /> },
              { path: ":id/update", element: <CategoriesUpdatePage /> },
            ],
          },
          {
            path: "manufacturers",
            children: [
              { index: true, element: <ManufacturersListPage /> },
              { path: "create", element: <ManufacturersCreatePage /> },
              { path: ":id/update", element: <ManufacturersUpdatePage /> },
            ],
          },
          {
            path: "models",
            children: [
              { index: true, element: <ModelsListPage /> },
              { path: "create", element: <ModelsCreatePage /> },
              { path: ":id/update", element: <ModelsUpdatePage /> },
            ],
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "roles",
            element: <Roles />,
          },
          {
            path: "references",
            element: <References />,
          },
          {
            path: "exchange-rates",
            children: [
              { index: true, element: <ExchangeRateListPage /> },
              { path: "create", element: <ExchangeRateCreatePage /> },
            ],
          },
          {
            path: "locations",
            children: [
              { index: true, element: <LocationListPage /> },
              { path: "create", element: <LocationCreatePage /> },
              { path: ":id/update", element: <LocationUpdatePage /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    // element: <AuthLayout />,
    element: <div>Auth</div>,
    children: [
      {
        path: "login",
        // element: <Login />,
        // loader: redirectIfUser,
      },
      {
        path: "logout",
        // action: logoutUser,
      },
    ],
  },
]);

export default router;
