import { FC } from 'react';
import { useGetLoanCallCentersQuery } from '../../app/services/callCenterApi';
import LayoutList from '../../components/LayoutList';
import { CURRENCY_SUFFIX } from '../../constants/vars';
import { formatNumber, renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const DebtorListPage: FC<Props> = () => {
  const { t, locale } = useLocale();

  const cols = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'client',
      label: t('client', 'Клиент'),
      render: (row: any) => renderFullName(row?.client),
    },
    {
      id: 'product',
      label: t('product', 'Товар'),
      render: (row: any) =>
        row.products.length > 0
          ? row.products[0].title[locale] + (row.products.length > 1 ? ' и еще ' + (row.products.length - 1) : '')
          : '',
    },
    {
      id: 'loan_amount',
      label: t('debtAmount', 'Сумма долга') + ',' + CURRENCY_SUFFIX,
      render: (row: any) => formatNumber(row.loan_amount),
    },
    // {
    //   id: "overdue",
    //   label: t("overdueDays", "Просрочено, дней"),
    // },
  ];

  return <LayoutList title={t('debtors', 'Должники')} cols={cols} query={useGetLoanCallCentersQuery} noAdd noEdit />;
};

export default DebtorListPage;
