import { FC } from 'react';
import { useGetSmsListQuery } from '../../app/services/smsApi';
import LayoutList from '../../components/LayoutList';
import useLocale from '../../hooks/useLocale';

interface Props {}

const SmsListPage: FC<Props> = () => {
  const { t } = useLocale();

  const cols = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'created_at',
      label: t('sent_date', 'Дата отправки'),
      render: (row: any) => new Date(row.created_at.slice(0, -1)).toLocaleString('ru'),
    },
    {
      id: 'phone',
      label: t('phone', 'Телефон'),
    },
    {
      id: 'message',
      label: t('message', 'Сообщение'),
    },
  ];

  return <LayoutList title={t('sms', 'SMS')} cols={cols} query={useGetSmsListQuery} />;
};

export default SmsListPage;
