import { t } from "i18next";
import { object, string } from "yup";

export const manufacturersValidationSchema = object().shape({
  name: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
  code: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
  description: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
});
