import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateProductMutation, useUpdateProductMutation } from '../../../app/services/productsApi';
import { DEFAULT_CURRENCY } from '../../../constants/vars';
import { handleRequestError } from '../../../helpers/form';
import { newDate, renderFullName } from '../../../helpers/utils';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { productValidationSchema } from './schema';

interface Props {
  data?: any;
  lists: {
    categories: RecordObject[];
    manufacturers: RecordObject[];
    models: RecordObject[];
    suppliers: RecordObject[];
  };
}

const ProductForm: FC<Props> = ({ data = null, lists: { categories, manufacturers, models, suppliers } }) => {
  const { t, locale } = useLocale();
  const navigate = useNavigate();

  const query = data ? useUpdateProductMutation : useCreateProductMutation;
  const [save, { isLoading }] = query();

  const initialValues = {
    product_category_id: '',
    manufacturer_id: '',
    model_id: '',
    serial_id_1: '',
    serial_id_2: '',
    merchant_id: '',
    price_income: '',
    currency_income: DEFAULT_CURRENCY,
    note: '',
    fields: [],
    date_income: newDate(),

    ...data,

    // product_category_id: '2',
    // manufacturer_id: 1,
    // model_id: 1,
    // serial_id_1: '123456789012345',
    // serial_id_2: '',
    // merchant_id: 16,
    // price_income: 500,
    // currency_income: 'usd',
    // note: '',
    // params: [],
    // date_income: '2023-09-30',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: productValidationSchema,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then(() => {
          toast.success(t('product.save.success', 'Товар успешно сохранен'));
          navigate('/dashboard/products');
        })
        .catch((error) => handleRequestError(error, formik));
    },
  });

  const filteredModels = useMemo(() => {
    // formik.setFieldValue('model_id', '');
    return (
      models.filter(
        (model) =>
          model.manufacturer_id === formik.values.manufacturer_id &&
          model.product_category_id === formik.values.product_category_id
      ) || []
    );
  }, [models, formik.values.manufacturer_id, formik.values.product_category_id]);

  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'product_category_id',
          label: t('category', 'Категория'),
          type: 'select',
          width: 4,
          options: categories.map((category: RecordObject) => ({
            label: category.name[locale],
            value: category.id,
          })),
          required: true,
        },
        {
          name: 'manufacturer_id',
          label: t('manufacturer', 'Производитель'),
          type: 'select',
          width: 4,
          options: manufacturers.map((manufacturer: RecordObject) => ({
            label: manufacturer.name,
            value: manufacturer.id,
          })),
          required: true,
        },
        {
          name: 'model_id',
          label: t('model', 'Модель'),
          type: 'select',
          width: 4,
          options: filteredModels.map((model: RecordObject) => ({
            label: model.name,
            value: model.id,
          })),
          required: true,
        },
        {
          name: 'serial_id_1',
          label: t('serialNumber', 'Серийный номер'),
          type: 'text',
          width: 6,
        },
        {
          name: 'serial_id_2',
          label: t('serialNumber', 'Серийный номер') + ' 2',
          type: 'text',
          width: 6,
        },
        {
          name: 'imei_1',
          label: t('imei', 'IMEI'),
          type: 'text',
          width: 6,
        },
        {
          name: 'imei_2',
          label: t('imei', 'IMEI') + ' 2',
          type: 'text',
          width: 6,
        },
        {
          name: 'merchant_id',
          label: t('supplier', 'Поставщик'),
          type: 'select',
          width: 4,
          options: suppliers.map((supplier: RecordObject) => ({
            label: renderFullName(supplier.merchant, 'contact_1'),
            value: supplier.id,
          })),
          required: true,
        },
        {
          name: 'price_income',
          label: t('incomePrice', 'Цена прихода'),
          type: 'number',
          width: 4,
          required: true,
          suffix: 'у.е.',
        },
        {
          name: 'date_income',
          label: t('incomeDate', 'Дата прихода'),
          type: 'date',
          width: 4,
        },
        {
          name: 'note',
          label: t('note', 'Примечание'),
          type: 'textarea',
          width: 12,
        },
      ],
    },
    {
      id: 'extra',
      title: t('extraParams', 'Дополнительные параметры'),
      fields: [
        {
          name: 'fields',
          label: null,
          type: 'params',
          width: 8,
        },
      ],
    },
  ];

  return (
    <Stack component={'form'} gap={2} onSubmit={formik.handleSubmit}>
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button size="large" variant="contained" color="inherit" onClick={() => navigate('/dashboard/products')}>
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading || !formik.isValid}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default ProductForm;
