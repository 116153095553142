import { Grid, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApplicationByIdQuery } from '../../app/services/applicationsApi';
import ContentLayout from '../../components/ContentLayout';
import PaymentsAct from '../../components/PaymentsAct';
import { CURRENCY_SUFFIX } from '../../constants/vars';
import { formatNumber, renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';

interface Props {}

const ActDetailsPage: FC<Props> = () => {
  const { locale } = useLocale();
  const { id } = useParams();
  const { data } = useGetApplicationByIdQuery(id);

  if (!data) return null;

  const application = data.data;

  const rows = [
    {
      id: 'customer',
      label: 'Клиент',
      value: renderFullName(application?.client),
    },
    {
      id: 'sale_date',
      label: 'Дата продажи',
      value: new Date(application?.created_at).toLocaleDateString('ru'),
    },
    {
      id: 'sale_amount',
      label: 'Сумма продажи',
      value: formatNumber(application?.price) + CURRENCY_SUFFIX,
    },
    {
      id: 'sale_prepayment',
      label: 'Сумма предоплаты',
      value: formatNumber(application?.prepayment) + CURRENCY_SUFFIX,
    },
    {
      id: 'term',
      label: 'Срок рассрочки',
      value: application?.loan_term + ' месяцев',
    },
    {
      id: 'credit_amount',
      label: 'Сумма рассрочки',
      value: formatNumber(application?.loan_amount) + CURRENCY_SUFFIX,
    },
    {
      id: 'monthly_payment',
      label: 'Ежемесячный платеж',
      value: formatNumber(application?.loan_monthly_payment) + CURRENCY_SUFFIX,
    },
    {
      id: 'box_given',
      label: 'Коробка выдана',
      value: application?.is_box_released ? 'Да' : 'Нет',
    },
    {
      id: 'has_protection',
      label: 'Защита',
      value: application?.is_protection_installed ? 'Установлена' : 'Не установлена',
    },
    {
      id: 'protection_login',
      label: 'Логин защиты',
      value: application?.protection_login,
      hide: !application?.is_protection_installed,
    },
  ];

  return (
    <ContentLayout title={'Акт сверки'}>
      <Stack gap={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" mb={2}>
              {t('general_info', 'Общая информация')}
            </Typography>
            <Table>
              <TableBody>
                {rows.map(
                  (row: any, index: number) =>
                    !row.hide && (
                      <TableRow key={index}>
                        <TableCell>{row.label}</TableCell>
                        <TableCell align="right">
                          {row.id === 'params'
                            ? row.value &&
                              typeof row.value === 'string' &&
                              row.value.map((param: any) => param.name + ': ' + param.value)
                            : row.value}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack gap={2}>
              <Typography variant="h6">{t('products', 'Товары')}</Typography>
              {application.products.map((product: any) => (
                <Table component={Paper}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Наименование</TableCell>
                      <TableCell>{product.title[locale]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Поставщик</TableCell>
                      <TableCell>{renderFullName(product.merchant, 'contact_1')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Дата прихода</TableCell>
                      <TableCell>{new Date(product.date_income).toLocaleDateString('ru')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Сумма прихода</TableCell>
                      <TableCell>{product.price_income} у.е.</TableCell>
                    </TableRow>
                    {(product.serial_id_1 || product.serial_id_2) && (
                      <TableRow>
                        <TableCell>Серийный номер</TableCell>
                        <TableCell>{[product.serial_id_1, product.serial_id_2].filter(Boolean).join(', ')}</TableCell>
                      </TableRow>
                    )}
                    {(product.imei_1 || product.imei_2) && (
                      <TableRow>
                        <TableCell>IMEI</TableCell>
                        <TableCell>{[product.imei_1, product.imei_2].filter(Boolean).join(', ')}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PaymentsAct data={application.acts} amount={application.loan_amount} />
          </Grid>
        </Grid>
      </Stack>
    </ContentLayout>
  );
};

export default ActDetailsPage;
