import { FormikProps, getIn } from 'formik';
import { toast } from 'react-toastify';

export const getFormikError = (formik: any, key: string) => {
  return getIn(formik.touched, key) && getIn(formik.errors, key);
};

export const hasFormikError = (formik: any, key: string) => {
  return Boolean(getFormikError(formik, key));
};

export const objectToFormData = (obj: any, form?: any, namespace?: any) => {
  const fd = form || new FormData();
  let formKey;
  for (let property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const isNotValid = (formik: FormikProps<any>) => !(formik.isValid && formik.dirty);

export const handleRequestError = (error: any, formik: FormikProps<any>) => {
  toast.error(error.data.message);

  if (error.status === 422) {
    const errorsData = error.data.data;
    // const formikErrors: any = {};
    Object.keys(errorsData).forEach((key) => {
      formik.setFieldError(key, errorsData[key].join(', '));
      // formikErrors[key] = errorsData[key].join(', ');
    });
    // formik.setErrors(formikErrors);
  }
};
