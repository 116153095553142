import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  baseQuery: rtkBaseQuery(''),
  tagTypes: ['Applications', 'ApplicationSchedules'],
  endpoints: (builder) => ({
    getApplicationList: builder.query({
      query: (params) => ({ url: '/applications', params }),
      providesTags: ['Applications'],
    }),
    getApplicationById: builder.query({
      query: (id) => `/applications/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Applications', id }],
    }),
    createApplication: builder.mutation({
      query: (body) => ({
        url: '/applications',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Applications'],
    }),
    updateApplication: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/applications/${id}`,
        method: 'POST',
        body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, { id }) => ['Applications', { type: 'Applications', id }],
    }),
    deleteApplication: builder.mutation({
      query: (id) => ({
        url: `/applications/${id}`,
        method: 'POST',
        body: { _method: 'DELETE' },
      }),
      invalidatesTags: (result, error, { id }) => ['Applications', { type: 'Applications', id }],
    }),

    getApplicationSchedules: builder.query({
      query: () => 'application-schedules',
      // providesTags: ['ApplicationSchedules'],
    }),
    getApplicationScheduleById: builder.query({
      query: (id) => `application-schedules/${id}`,
      // providesTags: (result, error, { id }) => ['ApplicationSchedules', { type: 'ApplicationSchedules', id }],
    }),
    setApplicationSchedulePayment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `application-schedules/${id}/payment`,
        method: 'POST',
        body: { ...body, _method: 'PATCH' },
      }),
      invalidatesTags: (result, error, { id }) => ['Applications', { type: 'Applications', id }],
      // invalidatesTags: (result, error, { id }) => ['ApplicationSchedules', { type: 'ApplicationSchedules', id }],
    }),

    getApplicationPayments: builder.query({
      query: () => 'application-schedule-payments',
      // providesTags: ["ApplicationSchedules"],
    }),
  }),
});

export const {
  useGetApplicationListQuery,
  useGetApplicationByIdQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useDeleteApplicationMutation,
  useGetApplicationSchedulesQuery,
  useGetApplicationScheduleByIdQuery,
  useSetApplicationSchedulePaymentMutation,
  useGetApplicationPaymentsQuery,
} = applicationsApi;
