import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { array, number, object, string } from 'yup';
import { useCreateApplicationMutation, useUpdateApplicationMutation } from '../../../app/services/applicationsApi';
import { CURRENCY_SUFFIX, DEFAULT_CURRENCY, LOCKING_SYSTEM } from '../../../constants/vars';
import { handleRequestError } from '../../../helpers/form';
import { newDate, renderFullName } from '../../../helpers/utils';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';

interface Props {
  data?: any;
  lists: {
    clients: any[];
    suppliers: any[];
    // products: any[];
  };
}

const SalesForm: FC<Props> = ({ data = null, lists: { clients, suppliers } }) => {
  const { t, locale } = useLocale();
  const navigate = useNavigate();

  const query = data ? useUpdateApplicationMutation : useCreateApplicationMutation;

  const [save, { isLoading }] = query();

  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          label: 'Клиент',
          name: 'client_id',
          type: 'select',
          options: clients.map((client) => ({
            label: renderFullName(client.client),
            value: client.id,
          })),
          width: 5,
          required: true,
        },
        // {
        //   // TODO: new supplier form
        //   name: 'merchant_id',
        //   label: 'Поставщик',
        //   type: 'select',
        //   options: suppliers.map((supplier) => ({
        //     label: renderFullName(supplier.merchant, 'contact_1'),
        //     value: supplier.id,
        //   })),
        //   width: 6,
        //   required: true,
        // },
        {
          label: 'Товары',
          name: 'products',
          type: 'products_selector',
          // options: productList.map((product: any) => ({
          //   label: `${product?.title[locale]} (${product?.price_income + CURRENCY_SUFFIX})`,
          //   value: product?.id,
          // })),
          width: 12,
          required: true,
        },
        {
          name: 'sold_at',
          label: 'Дата продажи',
          type: 'date',
          width: 4,
          required: true,
        },
        {
          name: 'price',
          label: 'Сумма продажи',
          type: 'number',
          width: 4,
          required: true,
          suffix: CURRENCY_SUFFIX,
        },
        {
          name: 'prepayment',
          label: 'Сумма предоплаты',
          type: 'number',
          width: 4,
          required: true,
          suffix: CURRENCY_SUFFIX,
        },
        {
          name: 'loan_term',
          label: 'Срок рассрочки, месяцев',
          type: 'select',
          options: Array.from({ length: 24 }, (_, i) => i + 1).map((i) => ({
            label: String(i),
            value: i,
          })),
          width: 4,
          required: true,
        },
        {
          name: 'loan_payment_at_day',
          label: 'День выплаты рассрочки',
          type: 'select',
          options: Array.from({ length: 30 }, (_, i) => i + 1).map((i) => ({
            label: String(i),
            value: i,
          })),
          width: 4,
          required: true,
        },
        {
          name: 'loan_amount',
          label: 'Сумма рассрочки',
          type: 'number',
          width: 4,
          readOnly: true,
          required: true,
          suffix: CURRENCY_SUFFIX,
        },
        // {
        //   name: "currency",
        //   label: "Валюта",
        //   type: "select",
        //   options: CURRENCIES,
        //   width: 4,
        //   required: true,
        // },
        {
          name: 'loan_monthly_payment',
          label: 'Ежемесячная выплата',
          type: 'number',
          width: 4,
          readOnly: true,
          required: true,
          suffix: CURRENCY_SUFFIX,
        },
        {
          name: 'is_box_released',
          label: 'Коробка выдана',
          type: 'select',
          width: 4,
          options: [
            { label: 'Да', value: '1' },
            { label: 'Нет', value: '0' },
          ],
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      client_id: '',
      currency: DEFAULT_CURRENCY,
      loan_amount: '',
      loan_monthly_payment: '',
      loan_payment_at_day: '',
      loan_term: '',
      prepayment: '',
      price: '',
      sold_at: newDate(),
      is_box_released: '0',
      is_protection_installed: '0',
      protection_login: '',
      // products: [],
      ...data,
      products: data
        ? data?.products.map(({ product_id, merchant_id }: any) => ({
            quantity: 1,
            merchant_id,
            product_id,
          }))
        : [],
    },
    validationSchema: object().shape({
      client_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      // merchant_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      currency: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      loan_amount: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      loan_monthly_payment: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      loan_payment_at_day: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      loan_term: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      prepayment: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      price: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      sold_at: string().required(t('errors.required', 'Поле обязательно для заполнения')),
      is_box_released: string(),
      is_protection_installed: string(),
      protection_login: string().when('is_protection_installed', {
        is: (val: string) => val !== '0',
        then: (schema) => schema.required(t('errors.required', 'Поле обязательно для заполнения')),
      }),
      products: array()
        .of(
          object().shape({
            quantity: number(),
            product_id: number(),
            merchant_id: number(),
          })
        )
        .min(1, 'errors.required'),
    }),
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then(() => {
          toast.success(t('sales.save.success', 'Продажа успешно сохранена'));
          navigate('/dashboard/sales');
        })
        .catch((error) => handleRequestError(error, formik));
    },
  });

  // useEffect(() => {
  //   if (formik.values.merchant_id) {
  //     getProductsByMerchantId(formik.values.merchant_id)
  //       .unwrap()
  //       .then((res) => {
  //         setProductList(res.data.items);
  //       })
  //       .catch(console.error);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.merchant_id]);

  useEffect(() => {
    const { price, prepayment } = formik.values;
    if (price >= 0 && prepayment >= 0) {
      if (prepayment > price) {
        formik.setFieldValue('prepayment', price);
      }
      const loan_amount = price - prepayment;
      if (loan_amount >= 0) formik.setFieldValue('loan_amount', loan_amount && loan_amount.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.price, formik.values.prepayment]);

  useEffect(() => {
    const loan_monthly_payment = formik.values.loan_amount / formik.values.loan_term;
    formik.setFieldValue('loan_monthly_payment', loan_monthly_payment.toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.loan_amount, formik.values.loan_term]);

  return (
    <Stack
      component={'form'}
      gap={2}
      onSubmit={(e) => {
        e.preventDefault();
        console.log(formik.errors, formik.values);
        formik.handleSubmit();
      }}
    >
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}

      <Stack key="protection" gap={2}>
        <Typography variant="h6">{t('protection', 'Защита')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormikField
              formik={formik}
              field={{
                name: 'is_protection_installed',
                label: 'Защита установлена',
                type: 'select',
                options: [
                  { label: 'Да', value: '1' },
                  { label: 'Нет', value: '0' },
                ],
              }}
            />
          </Grid>
          {formik.values.is_protection_installed === '1' && (
            <>
              <Grid item xs={12} md={4}>
                <FormikField
                  formik={formik}
                  field={{
                    name: 'protection_type',
                    label: 'Системы блокировки',
                    type: 'select',
                    options: LOCKING_SYSTEM,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikField
                  formik={formik}
                  field={{
                    name: 'protection_login',
                    label: 'Логин защиты',
                    type: 'text',
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button size="large" variant="contained" color="inherit" onClick={() => navigate('/dashboard/sales')}>
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default SalesForm;
