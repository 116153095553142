import { Typography } from "@mui/material";
import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { useGetManufacturersQuery } from "../../app/services/manufacturersApi";
import { useGetModelsQuery } from "../../app/services/modelsApi";
import { useGetProductCategoriesQuery } from "../../app/services/productCategoriesApi";
import { useGetSuppliersQuery } from "../../app/services/suppliersApi";
import ContentLayout from "../../components/ContentLayout";
import ProductForm from "../../components/forms/product/ProductForm";

interface Props {}

const ProductCreatePage: ComponentType<Props> = () => {
  const { t } = useTranslation();
  const { data: categoriesData } = useGetProductCategoriesQuery({
    limit: 999,
    sort: "id",
  });
  const { data: manufacturersData } = useGetManufacturersQuery({
    limit: 999,
    sort: "id",
  });
  const { data: modelsData } = useGetModelsQuery({
    limit: 999,
    sort: "id",
  });
  const { data: suppliersData } = useGetSuppliersQuery({
    limit: 9999,
    sort: "id",
  });

  if (!categoriesData || !manufacturersData || !modelsData || !suppliersData)
    return <Typography>{t("loading", "Загрузка...")}</Typography>;

  return (
    <ContentLayout title={t("products.new", "Новый товар")}>
      <ProductForm
        lists={{
          categories: categoriesData.data.items,
          manufacturers: manufacturersData.data.items,
          models: modelsData.data.items,
          suppliers: suppliersData.data.items,
        }}
      />
    </ContentLayout>
  );
};

export default ProductCreatePage;
