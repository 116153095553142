import { Box, Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSendSmsMutation } from "../../../app/services/smsApi";
import { CATEGORY_FOR_SMS } from "../../../constants/vars";
import useLocale from "../../../hooks/useLocale";
import FormikField from "../../Form/FormikField";
import { smsValidationSchema } from "./schema";

interface Props {}

const SMSForm: FC<Props> = () => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [sendSMS, { isLoading }] = useSendSmsMutation();
  const initialValues = {
    message: "",
    category: "other",
    phones: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: smsValidationSchema,
    onSubmit: (values) => {
      sendSMS(values)
        .unwrap()
        .then((_res) => {
          navigate("/dashboard/sms");
        })
        .catch(console.error);
    },
  });
  return (
    <Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
      {false && (
        <FormikField
          formik={formik}
          field={{
            name: "category",
            label: t("sms.form.category", "Категория"),
            type: "select",
            options: CATEGORY_FOR_SMS,
          }}
        />
      )}
      {formik.values.category === "other" && (
        <FormikField
          formik={formik}
          field={{
            name: "phones",
            label: t("sms.form.phones", "Телефоны"),
            type: "textarea",
          }}
        />
      )}
      <FormikField
        formik={formik}
        field={{
          name: "message",
          label: t("sms.form.message", "Сообщение"),
          type: "textarea",
        }}
      />

      <Box>
        <Button
          variant="contained"
          size="large"
          disabled={!formik.isValid || isLoading}
          type="submit"
        >
          {t("action.send", "Отправить")}
        </Button>
      </Box>
    </Stack>
  );
};

export default SMSForm;
