import { FC } from "react";
import { useGetLocationsQuery } from "../../../app/services/locationsApi";
import LayoutList from "../../../components/LayoutList";
import { LOCATION_TYPE } from "../../../constants/vars";
import useLocale from "../../../hooks/useLocale";

interface Props {}

const LocationListPage: FC<Props> = () => {
  const { t } = useLocale();

  const cols = [
    { id: "id", label: "ID", minWidth: 50 },
    {
      id: "name",
      label: t("name", "Название"),
      render: (row: any) => row.name,
    },
    { id: "code", label: t("code", "Код"), minWidth: 100 },
    {
      id: "type",
      label: t("type", "Тип"),
      render: (row: any) =>
        LOCATION_TYPE.find((item) => item.value === row.type)?.label,
    },
    {
      id: "level",
      label: t("level", "Уровень"),
    },
  ];

  return (
    <LayoutList
      title={t("locations", "Местоположения")}
      cols={cols}
      query={useGetLocationsQuery}
      noDetails
      filter="none"
      noAdd
      noEdit
    />
  );
};

export default LocationListPage;
