import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { CURRENCY_SUFFIX } from '../constants/vars';
import { formatNumber } from '../helpers/utils';
import useDialog from '../hooks/useDialog';
import useLocale from '../hooks/useLocale';
import PaymentForm from './forms/payment/PaymentForm';

interface Props {
  data: any;
  currency: Currency;
}

const PaymentSchedule: FC<Props> = ({ data, currency }) => {
  const { t } = useLocale();
  const { openDialog, closeDialog, renderDialog } = useDialog();

  const handleMakePayment = (month: number, data: RecordObject) => {
    openDialog(<PaymentForm month={month} data={data} currency={currency} close={closeDialog} />);
  };

  return (
    <TableContainer>
      <Typography variant="h6" mb={2}>
        {t('payment_schedule', 'График погашений')}
      </Typography>
      <Table component={Paper} sx={{ '& .MuiTableCell-root': { textAlign: 'center' } }}>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>
              {t('payment.balance', 'Остаток')},{CURRENCY_SUFFIX}
            </TableCell>
            <TableCell>
              {t('payment.amount', 'Сумма оплаты')},{CURRENCY_SUFFIX}
            </TableCell>
            <TableCell>
              {t('payment.paid', 'Оплачено')},{CURRENCY_SUFFIX}
            </TableCell>
            <TableCell>
              {t('payment.left', 'Остаток к оплате')},{CURRENCY_SUFFIX}
            </TableCell>
            <TableCell>{t('payment.date', 'Дата оплаты')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((payment: any) => (
            <TableRow key={payment.number}>
              <TableCell>{payment.number}</TableCell>
              <TableCell>{formatNumber(payment.balance)}</TableCell>
              <TableCell>{formatNumber(payment.payment_amount)}</TableCell>
              <TableCell>{formatNumber(payment.paid)}</TableCell>
              <TableCell>{formatNumber(payment.need_to_pay)}</TableCell>
              <TableCell>{new Date(payment.payment_at).toLocaleDateString('ru')}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleMakePayment(payment.number, payment)}
                  disabled={payment.need_to_pay === 0}
                >
                  {payment.need_to_pay === 0 ? t('paid', 'Оплачено') : t('payment', 'Оплата')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {renderDialog()}
    </TableContainer>
  );
};

export default PaymentSchedule;
