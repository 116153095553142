import { AppBar, Toolbar } from "@mui/material";
import { ComponentType } from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import ThemeSwitcher from "./ThemeSwitcher";
import UserPanel from "./UserPanel";

interface HeaderProps {}

const Header: ComponentType<HeaderProps> = () => {
  return (
    <AppBar position="sticky" color="default">
      <Toolbar sx={{ justifyContent: "flex-end", gap: 2 }}>
        {false && <LanguageSwitcher />}
        {false && <ThemeSwitcher />}
        <UserPanel />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
