import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next/initReactI18next";

export const localesMap = {
  ru: { label: "Русский", short: "Ру" },
  uz: { label: "O'zbek", short: "O'z" },
  en: { label: "English", short: "En" },
};

export type Locale = keyof typeof localesMap;

export interface LocaleProps {
  label: string;
  short: string;
}
export const defaultLocale: Locale =
  (localStorage.getItem("locale") as Locale) || "ru";

export const locales = Object.keys(localesMap) as Locale[];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // debug: true,
    backend: {
      loadPath: "/translations/{{lng}}.json",
    },
    // detection: {
    //   // lookupFromPathIndex: 0,
    //   order: ["path"],
    // },
    fallbackLng: defaultLocale,
    // fallbackNS: false,
    // interpolation: {
    //   escapeValue: false,
    // },
    // keySeparator: false,
    // ns: [],
    returnNull: false,
    returnEmptyString: true,
    supportedLngs: locales,
  });
