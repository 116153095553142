import { t } from "i18next";
import { object, string } from "yup";

export const smsValidationSchema = object().shape({
  message: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
  category: string().required(
    t("errors.required", "Поле обязательно для заполнения")
  ),
  phones: string().when("category", {
    is: "other",
    then: (schema) =>
      schema.required(t("errors.required", "Поле обязательно для заполнения")),
  }),
});
