import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { getSearchParams } from '../helpers/utils';
import useLocale from '../hooks/useLocale';
import ContentLayout from './ContentLayout';
import ListFilter from './ListFilter';
import DataTable from './Table/DataTable';

interface LayoutListProps {
  query?: any;
  queryParams?: any;
  title: string;
  cols: any[];
  noDetails?: boolean;
  noEdit?: boolean;
  noAdd?: boolean;
  filter?: 'full' | 'search' | 'none';
  deleteQuery?: (id: number) => void;
}

const LayoutList: FC<LayoutListProps> = ({
  query,
  queryParams,
  title,
  cols,
  noDetails = false,
  noEdit = false,
  noAdd = false,
  filter = 'search',
  deleteQuery,
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useLocale();
  const [props, setProps] = useState(getSearchParams());
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { data, isFetching } = query({ ...props, ...queryParams });

  useEffect(() => {
    if (data) {
      setRows(data.data.items);
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setProps(getSearchParams());
  }, [searchParams]);

  // useEffect(() => {
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props]);

  return (
    <ContentLayout
      title={title}
      actions={
        !noAdd && (
          <Button component={Link} to="create" variant="contained" color="primary" startIcon={<Add />}>
            {t('action.add', 'Добавить')}
          </Button>
        )
      }
    >
      {filter !== 'none' && <ListFilter type={filter} />}
      <DataTable
        {...{
          cols,
          rows,
          total,
          props,
          setProps,
          disableDetails: noDetails,
          disableEdit: noEdit,
          isLoading: isFetching,
          onDelete: deleteQuery,
          disableActions: noEdit && !deleteQuery,
        }}
      />
    </ContentLayout>
  );
};

export default LayoutList;
