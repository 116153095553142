import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontFamily: "Roboto",
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    // fontFamily: "Montserrat",
    fontSize: 64,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: -2.14,
  },
  h2: {
    // fontFamily: "Montserrat",
    fontSize: 56,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: -1.34,
  },
  h3: {
    // fontFamily: "Montserrat",
    fontSize: 48,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: -1.07,
  },
  h4: {
    // fontFamily: "Montserrat",
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: -0.74,
  },
  h5: {
    // fontFamily: "Montserrat",
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: -0.47,
  },
  h6: {
    // fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: -0.33,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: -0.26,
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: -0.18,
  },
  body1: {
    fontSize: 18,
    lineHeight: 1.4,
    letterSpacing: -0.26,
  },
  body2: {
    fontSize: 16,
    lineHeight: 1.4,
    letterSpacing: -0.18,
  },
  button: {
    // fontSize: 12,
    // fontWeight: 500,
    // lineHeight: 1,
    // letterSpacing: -0.18,
    // textTransform: "none",
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.4,
    letterSpacing: 0.01,
  },
  overline: {
    fontSize: 10,
    lineHeight: 1.4,
    letterSpacing: 0.1,
  },
};

export default typography;
