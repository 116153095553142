import { FC } from "react";
import ContentLayout from "../../components/ContentLayout";
import SMSForm from "../../components/forms/sms/SMSForm";

interface Props {}

const SmsCreatePage: FC<Props> = () => {
  return (
    <ContentLayout title="Отправка SMS">
      <SMSForm />
    </ContentLayout>
  );
};

export default SmsCreatePage;
