import { FC } from "react";
import { useGetExchangeRatesQuery } from "../../../app/services/settingsApi";
import LayoutList from "../../../components/LayoutList";
import { CURRENCIES } from "../../../constants/vars";
import { getLabelFromOptions } from "../../../helpers/utils";
import useLocale from "../../../hooks/useLocale";

interface Props {}

const ExchangeRateListPage: FC<Props> = () => {
  const { t } = useLocale();

  const cols = [
    {
      id: "date_at",
      label: t("date", "Дата"),
      render: (row: any) => new Date(row.date_at).toLocaleDateString("ru"),
    },
    {
      id: "currency",
      label: t("currency", "Валюта"),
      render: (row: any) => getLabelFromOptions(CURRENCIES, row.currency),
    },
    {
      id: "rate",
      label: t("rate", "Курс") + ", cум",
    },
    {
      id: "actions",
      label: "",
    },
  ];
  return (
    <LayoutList
      title={t("exchangeRates", "Курсы валют")}
      cols={cols}
      query={useGetExchangeRatesQuery}
      noDetails
      noEdit
      filter="none"
    />
  );
};

export default ExchangeRateListPage;
