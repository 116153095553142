import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLogoutMutation } from "../app/services";

const useAuth = () => {
  const token = localStorage.getItem("token");

  const [isAuthorized, setIsAuthorized] = useState(!!token);
  const navigate = useNavigate();
  const [logoutAction] = useLogoutMutation();

  const authorize = (data: Record<string, any>) => {
    const { token, ...user } = data.data;
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    setIsAuthorized(true);
    setTimeout(() => {
      navigate("/dashboard");
    }, 500);
  };

  const logout = () => {
    logoutAction(null)
      .unwrap()
      .then(() => {
        toast.success("Вы успешно вышли из системы");
        localStorage.clear();
        setIsAuthorized(false);
        navigate("/");
      });
  };

  return {
    isAuthorized,
    authorize,
    logout,
  };
};

export default useAuth;
