import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const productCategoriesApi = createApi({
  reducerPath: 'productCategoriesApi',
  baseQuery: rtkBaseQuery('/product-categories'),
  tagTypes: ['ProductCategories'],
  endpoints: (builder) => ({
    getProductCategories: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['ProductCategories'],
    }),
    getProductCategoryById: builder.query({
      query: (id) => `${id}`,
      providesTags: ['ProductCategories'],
    }),
    createProductCategory: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProductCategories'],
    }),
    updateProductCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'POST',
        body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, { id }) => ['ProductCategories', { type: 'ProductCategories', id }],
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  useGetProductCategoryByIdQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
} = productCategoriesApi;
