import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateManufacturerMutation, useUpdateManufacturerMutation } from '../../../app/services/manufacturersApi';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { manufacturersValidationSchema } from './schema';

interface Props {
  data?: any;
}

const ManufacturersForm: FC<Props> = ({ data }) => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const query = data ? useUpdateManufacturerMutation : useCreateManufacturerMutation;

  const [save, { isLoading }] = query();

  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'code',
          label: t('code', 'Код'),
          type: 'text',
          width: 12,
        },
        {
          name: 'name',
          label: t('name', 'Название'),
          type: 'text',
          width: 12,
        },
        {
          name: 'description',
          label: t('description', 'Описание'),
          type: 'textarea',
          width: 12,
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      description: '',

      ...data,
    },
    validationSchema: manufacturersValidationSchema,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then((_) => {
          navigate('/dashboard/settings/manufacturers');
        })
        .catch(console.error);
    },
  });

  console.log('formik.values', formik.values);

  return (
    <Stack component={'form'} gap={2} onSubmit={formik.handleSubmit}>
      {form_sections.map(({ id, title, fields }) => (
        <Stack key={id} p={2} component={Card}>
          {title && <Typography variant="h6">{title}</Typography>}
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button
          size="large"
          variant="contained"
          color="inherit"
          onClick={() => navigate('/dashboard/settings/manufacturers')}
        >
          {t('action.cancel', 'Отмена')}
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={isLoading || !formik.isValid}>
          {t('action.save', 'Сохранить')}
        </Button>
      </Box>
    </Stack>
  );
};

export default ManufacturersForm;
