import { Components } from "@mui/material";

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      "*, *::before, *::after": {
        boxSizing: "border-box",
      },
      a: {
        textDecoration: "none",
        // color: palette.primary.main,
      },
      p: {
        marginTop: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 16,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        // borderWidth: 1,
        // borderStyle: "solid",
        borderRadius: 4,
        overflow: "hidden",
        // padding: 8,

        "&.Mui-error": {
          // borderColor: "#f44336",
        },
      },
      input: {
        // padding: 0,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {},
      inputRoot: {
        ".MuiAutocomplete-input": {
          padding: 0,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: 0,
        textTransform: "none",
      },
    },
  },

  MuiTable: {
    styleOverrides: {
      root: {
        border: "1px solid lightgrey",
      },
    },
  },
  MuiTableCell: {
    defaultProps: {
      // align: "center",
    },
    styleOverrides: {
      root: {
        padding: "8px 16px",
        border: "1px solid lightgrey",
      },
      head: {
        paddingTop: 16,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 10,
      },
    },
  },
};

export default components;
