import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { API_BASE_URL } from "../constants/vars";

export const extraHeaders = (headers: Headers) => {
  headers.set("Accept", "application/json");
  headers.set("api-version", "1");
  headers.set("platform", "web");
  headers.set("language", "ru");
  // headers.set("Content-Type", "multipart/form-data");

  const token = localStorage.getItem("token");
  if (token) headers.set("Authorization", `Bearer ${token}`);
  return headers;
};

export const rtkBaseQuery = (path: string = "/") =>
  fetchBaseQuery({
    baseUrl: API_BASE_URL + path,
    prepareHeaders: extraHeaders,
  });

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = action.payload.status || null;
    const message = action.payload.data.message || "We got a rejected action!";
    if (status === 401) {
      sessionStorage.clear();
      console.error("Unauthorized! Redirecting to login...");
      window.location.href = "/";
    } else if (status > 300 && status < 600) {
      console.warn(message);
    }
  }

  return next(action);
};
