import { Add, DeleteOutline } from '@mui/icons-material';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import FormField from './FormField';

interface Props {
  name: string;
  data?: any[];
  onChange: (val: any) => void;
}

const rowTpl = {
  name: '',
  value: '',
};

const ParamsFields: FC<Props> = ({ name, data, onChange }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any[]>(data?.map(({ name, value }) => ({ name, value })) || []);

  const handleAddRow = () => {
    setRows((prev) => [...prev, rowTpl]);
  };

  const handleDeleteRow = (index: number) => {
    setRows((prev) => [...prev.filter((_, i) => i !== index)]);
  };

  const handleChange = (index: number, name: string, value: any) => {
    setRows((prev) =>
      prev.map((row, i) => {
        return index !== i ? row : { ...row, [name]: value };
      })
    );
  };

  useUpdateEffect(() => {
    onChange(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <TableContainer>
      <Table
        sx={{
          border: 0,
          '& .MuiTableCell-root': {
            padding: '0 8px 8px 0',
            border: 0,
          },
        }}
        border={0}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ px: 1 }}>{t('label', 'Поле')}</TableCell>
            <TableCell>{t('value', 'Значение')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{ mb: 1 }}>
              <TableCell>
                <FormField
                  name={'params.' + index + '.name'}
                  value={row.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <FormField
                  name={'params.' + index + '.value'}
                  value={row.value}
                  onChange={(e) => handleChange(index, 'value', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Button size="small" color="error" onClick={() => handleDeleteRow(index)}>
                  <DeleteOutline />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="contained" color="inherit" size="small" fullWidth onClick={handleAddRow}>
        <Add /> {t('action.add', 'Добавить')}
      </Button>
    </TableContainer>
  );
};

export default ParamsFields;
