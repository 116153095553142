import { FC } from "react";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../components/ContentLayout";
import ClientForm from "../../components/forms/client/ClientForm";

interface Props {}

const ClientCreatePage: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <ContentLayout title={t("customers.new", "Новый клиент")}>
      <ClientForm />
    </ContentLayout>
  );
};

export default ClientCreatePage;
