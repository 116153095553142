import { Box, Stack, Typography } from "@mui/material";
import { ComponentType, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";

interface ContentLayoutProps {
  title: string;
  actions?: ReactNode;
  children?: ReactNode;
}

const ContentLayout: ComponentType<ContentLayoutProps> = ({
  title,
  actions,
  children,
}) => {
  return (
    <Stack>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">{title}</Typography>
        <Box>{actions}</Box>
      </Box>
      <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
        <Box>{children}</Box>
      </ErrorBoundary>
    </Stack>
  );
};

export default ContentLayout;
