import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: rtkBaseQuery('/settings'),
  tagTypes: ['Settings', 'ExchangeRates'],
  endpoints: (builder) => ({
    getExchangeRates: builder.query({
      query: (params) => ({ url: '/exchange-rates', params }),
      providesTags: ['ExchangeRates'],
    }),
    setExchangeRate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `exchange-rates`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExchangeRates'],
    }),
  }),
});

export const { useGetExchangeRatesQuery, useSetExchangeRateMutation } = settingsApi;
