import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetManufacturerByIdQuery } from "../../../app/services/manufacturersApi";
import ContentLayout from "../../../components/ContentLayout";
import ManufacturersForm from "../../../components/forms/manufacturer/ManufacturersForm";

interface Props {}

const ManufacturersUpdatePage: FC<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useGetManufacturerByIdQuery(id);

  if (!data) return null;

  return (
    <ContentLayout
      title={t("manufacturers.edit", "Редактирование производителя")}
    >
      <ManufacturersForm data={data.data} />
    </ContentLayout>
  );
};

export default ManufacturersUpdatePage;
