import { t } from 'i18next';
import { array, object, string } from 'yup';

export const productValidationSchema = object().shape({
  product_category_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  manufacturer_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  model_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  serial_id_1: string().nullable(),
  serial_id_2: string().nullable(),
  merchant_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  price_income: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  currency_income: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  date_income: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  note: string().nullable(),
  fields: array().of(
    object().shape({
      label: string(),
      value: string(),
    })
  ),
});
