import { Delete } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetProductsByMerchantIdQuery } from '../../app/services/productsApi';
import { useGetSuppliersWithStockQuery } from '../../app/services/suppliersApi';
import useLocale from '../../hooks/useLocale';
import FormField from './FormField';

interface Props {
  index: number;
  data: { quantity: number }; // Specify the type of 'data'
  onChange: (index: number, row: { id?: number; quantity: number; merchant_id?: number }) => void;
  onDelete: (index: number) => void;
  selectedProducts: { id?: number; quantity: number; merchant_id?: number }[];
}

const ProductSelectorRow = ({ index, data, onChange, onDelete, selectedProducts = [] }: Props) => {
  const { locale } = useLocale();
  const usedIds = selectedProducts?.map((item) => item.id).filter(Boolean);
  // const [query, setQuery] = useState('');
  // const debouncedQuery = useDebounce(query, 500);

  const [selectedSupplier, setSelectedSupplier] = useState<number>();
  const [selectedProduct, setSelectedProduct] = useState<number>();

  // const { data: suppliersData } = useGetSuppliersQuery({ limit: 10000 });
  const { data: productsData, isFetching: productsFetching } = useGetProductsByMerchantIdQuery(
    { merchant_id: selectedSupplier },
    { skip: !selectedSupplier }
  );

  const { data: suppliersData, isFetching: suppliersFetching } = useGetSuppliersWithStockQuery({
    product_ids: usedIds.join(','),
  });

  // const handleFindSuppliers = (val: string) => {
  //   if (val.length > 2) {
  //     setQuery(val);
  //   }
  // };

  const productOptions = productsData?.data?.items
    .filter((item: any) => !usedIds.includes(item.id))
    .map((item: any) => ({
      label: item.title[locale] + ' (IMEI: ***' + String(item.imei_1).slice(-5) + ')',
      value: item.id,
    }));

  useEffect(() => {
    onChange(index, { id: selectedProduct, quantity: data.quantity, merchant_id: selectedSupplier });
  }, [selectedProduct]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5}>
        <Autocomplete
          loading={suppliersFetching}
          loadingText="Загрузка..."
          options={suppliersData?.data ?? []}
          noOptionsText={'Нет результатов'}
          // options={suppliersOptions || []}
          getOptionLabel={(option: any) => option.label}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.value}>
              {option.label}
            </MenuItem>
          )}
          renderInput={(params) => <FormField {...params} fullWidth placeholder="Выберите поставщика" />}
          fullWidth
          // value={suppliersOptions?.find((o: FormikFieldOptionProps) => +o.value === selectedSupplier) || undefined}
          onChange={(_, item) => setSelectedSupplier(item?.value)}
          // onInputChange={(e, val) => handleFindSuppliers(val)}
          isOptionEqualToValue={(option: any, value: any) => option.value === selectedSupplier}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <Stack direction="row" width={1} alignItems="center" gap={2}>
          <Autocomplete
            options={productOptions || []}
            noOptionsText="Нет результатов"
            getOptionLabel={(option: any) => option.label}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.value}>
                {option.label}
              </MenuItem>
            )}
            renderInput={(params) => (
              <FormField {...params} fullWidth placeholder={productsFetching ? 'Загрузка...' : 'Выберите товар'} />
            )}
            fullWidth
            // value={[]?.find((o: any) => +o.value === selectedProduct)}
            onChange={(_, item) => setSelectedProduct(item?.value)}
            isOptionEqualToValue={(option: any, value: any) => option.value === selectedProduct}
            disabled={productsFetching}
          />

          <IconButton color="error" onClick={() => onDelete(index)}>
            <Delete />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProductSelectorRow;
