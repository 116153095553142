import { t } from 'i18next';
import { object, string } from 'yup';

export const categoryValidationSchema = object().shape({
  name: object().shape({
    ru: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    uz: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    en: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  }),
  code: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  description: object().shape({
    ru: string().nullable(),
    uz: string().nullable(),
    en: string().nullable(),
  }),
});
