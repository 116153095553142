import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { CURRENCY_SUFFIX } from '../constants/vars';
import { formatNumber } from '../helpers/utils';

interface Props {
  data: RecordObject[];
  amount: number;
}

const PaymentsAct: FC<Props> = ({ data, amount }) => {
  const total_paid = data.reduce((acc: number, row: RecordObject) => {
    return acc + Number(row.paid);
  }, 0);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Дата оплаты</TableCell>
            <TableCell>Сумма к оплате,{CURRENCY_SUFFIX}</TableCell>
            <TableCell>Оплачено,{CURRENCY_SUFFIX}</TableCell>
            <TableCell>Статус</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row: any, index: number) => (
            <TableRow key={index}>
              <TableCell>{row.number}</TableCell>
              <TableCell>{new Date(row.payment_at).toLocaleDateString('ru')}</TableCell>
              <TableCell>{formatNumber(row.payment_amount)}</TableCell>
              <TableCell>{formatNumber(row.paid)}</TableCell>
              <TableCell>{row.payment_status}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>Итого Оплачено</TableCell>
            <TableCell>
              {formatNumber(total_paid)} {CURRENCY_SUFFIX}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>Остаток</TableCell>
            <TableCell>
              {formatNumber(amount - total_paid)} {CURRENCY_SUFFIX}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default PaymentsAct;
