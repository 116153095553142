import { FC } from "react";
import { useGetLocationsQuery } from "../../app/services/locationsApi";
import LocationSelectorContainer from "./LocationSelectorContainer";

const LocationSelector: FC<any> = (props) => {
  const { data } = useGetLocationsQuery({ limit: 999, sort: "id" });

  if (!data) return null;

  return <LocationSelectorContainer {...props} locations={data.data.items} />;
};

export default LocationSelector;
