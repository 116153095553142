import { ComponentType, createElement } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const withAuthorization = () => (Component: ComponentType) => {
  const EnhancedComponent: ComponentType<any> = (props) => {
    const { isAuthorized } = useAuth();

    if (!isAuthorized) {
      console.log("Bye bye!!!");

      return createElement(Navigate, { to: "/" });
    }

    return createElement(Component, props);
  };

  return EnhancedComponent;
};
