import { FC } from 'react';
import { useGetModelsQuery } from '../../../app/services/modelsApi';
import { useGetProductCategoriesQuery } from '../../../app/services/productCategoriesApi';
import LayoutList from '../../../components/LayoutList';
import useLocale from '../../../hooks/useLocale';

interface Props {}

const ModelsListPage: FC<Props> = () => {
  const { t, locale } = useLocale();
  const { data: categories } = useGetProductCategoriesQuery({ limit: 999 });

  console.log('categories', categories);

  const cols = [
    { id: 'id', label: 'ID', minWidth: 50 },
    {
      id: 'name',
      label: t('model', 'Модель'),
      render: (row: any) => row.name,
    },
    {
      id: 'manufacturer',
      label: t('manufacturer', 'Производитель'),
      render: (row: any) => row?.manufacturer?.name,
    },
    {
      id: 'product_category_id',
      label: t('category', 'Категория'),
      render: (row: any) => categories?.data.items.find((cat: any) => cat.id === row.product_category_id).name[locale],
    },

    { id: 'code', label: t('code', 'Код'), minWidth: 100 },
    // {
    //   id: "description",
    //   label: t("description", "Описание"),
    //   render: (row: any) => row?.description,
    // },
  ];

  return <LayoutList title={t('models', 'Модели')} cols={cols} query={useGetModelsQuery} noDetails filter="none" />;
};

export default ModelsListPage;
