import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { rtkBaseQuery } from '../../helpers/rtkquery';

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery: rtkBaseQuery('/clients'),
  tagTypes: ['Clients'],
  endpoints: (builder) => ({
    getClients: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['Clients'],
    }),
    getClientById: builder.query({
      query: (id) => `${id}`,
      providesTags: ['Clients'],
    }),
    createClient: builder.mutation({
      query: ({ id, body }) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Clients'],
    }),
    updateClient: builder.mutation({
      query: ({ id, body }) => ({
        url: `${id}`,
        method: 'POST',
        body,
        // body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, { id }) => ['Clients', { type: 'Clients', id }],
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'POST',
        body: { _method: 'DELETE' },
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientsApi;
