import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: rtkBaseQuery('/smses'),
  tagTypes: ['Sms'],
  endpoints: (builder) => ({
    getSmsList: builder.query({
      query: (params) => ({ url: '', params }),
      providesTags: ['Sms'],
    }),
    sendSms: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Sms'],
    }),
  }),
});

export const { useGetSmsListQuery, useSendSmsMutation } = smsApi;
