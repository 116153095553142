import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { useGetSuppliersQuery } from "../../app/services/suppliersApi";
import LayoutList from "../../components/LayoutList";
import { renderFullName } from "../../helpers/utils";

interface Props {}

const SuppliersListPage: ComponentType<Props> = () => {
  const { t } = useTranslation();

  const columns = [
    { id: "id", label: "ID", props: { width: 50 } },
    {
      id: "fullname",
      label: t("fullNameAbbr", "ФИО"),
      props: {
        minWidth: 200,
      },
      render: (row: Record<string, any>) =>
        renderFullName(row.merchant, "contact_1"),
    },
    {
      id: "phone",
      label: t("phone", "Номер телефона"),
      minWidth: 120,
      render: (row: Record<string, any>) => row.merchant.contact_1_phone,
    },
    {
      id: "merchant.company_name",
      label: t("orgName", "Название организации"),
      minWidth: 200,
      render: (row: Record<string, any>) => row.merchant.company_name,
    },
  ];

  return (
    <LayoutList
      title={t("suppliers", "Поставщики")}
      cols={columns}
      query={useGetSuppliersQuery}
    />
  );
};

export default SuppliersListPage;
