import ProductCreatePage from "./ProductCreatePage";
import ProductDetailsPage from "./ProductDetailsPage";
import ProductListPage from "./ProductListPage";
import ProductUpdatePage from "./ProductUpdatePage";

const Products = {
  List: ProductListPage,
  Create: ProductCreatePage,
  Details: ProductDetailsPage,
  Update: ProductUpdatePage,
};

export default Products;
