import { FC } from "react";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../../components/ContentLayout";
import ManufacturersForm from "../../../components/forms/manufacturer/ManufacturersForm";

interface Props {}

const ManufacturersCreatePage: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <ContentLayout title={t("manufacturers.new", "Новый производитель")}>
      <ManufacturersForm />
    </ContentLayout>
  );
};

export default ManufacturersCreatePage;
