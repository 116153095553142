import { t } from 'i18next';
import { object, string } from 'yup';

export const supplierValidationSchema = object().shape({
  merchant: object().shape({
    contact_1_last_name: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    contact_1_first_name: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    contact_1_second_name: string().nullable(),
    contact_1_phone: string().min(12).required(t('errors.required', 'Поле обязательно для заполнения')),
    contact_1_email: string().email(t('error.email', 'Неверный формат email')).nullable(),
    is_legal: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    company_name: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    location_id: string().required(t('errors.required', 'Поле обязательно для заполнения')),
    company_legal_address: string().required(t('errors.required', 'Поле обязательно для заполнения')),
  }),
});
