import { Box, Button, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { useSetApplicationSchedulePaymentMutation } from '../../../app/services/applicationsApi';
import { CURRENCY_SUFFIX, PAYMENT_TYPE } from '../../../constants/vars';
import { handleRequestError } from '../../../helpers/form';
import { newDate } from '../../../helpers/utils';
import useLocale from '../../../hooks/useLocale';
import FormikField from '../../Form/FormikField';
import { paymentValidationSchema } from './schema';

interface Props {
  month: number;
  data: RecordObject;
  close: () => void;
  currency: Currency;
}

const PaymentForm: FC<Props> = ({ month, data, close, currency }) => {
  const { t } = useLocale();
  const [save, { isLoading }] = useSetApplicationSchedulePaymentMutation();
  const form_sections: Section[] = [
    {
      id: 'general',
      title: null,
      fields: [
        {
          name: 'payment_at',
          label: 'Дата оплаты',
          type: 'date',
          width: 12,
        },
        {
          name: 'type',
          label: 'Тип оплаты',
          type: 'select',
          width: 12,
          options: PAYMENT_TYPE,
        },
        {
          name: 'amount',
          label: 'Сумма оплаты',
          type: 'number',
          width: 12,
          suffix: CURRENCY_SUFFIX,
        },
        // {
        //   name: "currency",
        //   label: "Валюта",
        //   type: "select",
        //   width: 6,
        //   options: CURRENCIES,
        //   readOnly: true,
        // },
        {
          name: 'note',
          label: 'Примечание',
          type: 'textarea',
          width: 12,
        },
      ],
    },
  ];

  const formik = useFormik({
    initialValues: {
      payment_at: newDate(),
      amount: data.need_to_pay,
      currency,
      type: 'cash',
      note: '',
    },
    validationSchema: paymentValidationSchema,
    onSubmit: (values) => {
      // console.log(values);
      save({ id: data.id, month, ...values })
        .unwrap()
        .then(() => {
          toast.success(t('payment.save.success', 'Оплата успешно сохранена'));
          close();
        })
        .catch((error: any) => handleRequestError(error, formik));
    },
  });

  return (
    <>
      <DialogTitle>Оплата</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {form_sections.map(({ id, title, fields }) =>
            fields.map((field) => (
              <Grid item xs={12} md={field.width} key={field.name}>
                <FormikField formik={formik} field={field} />
              </Grid>
            ))
          )}
          <Grid item>
            <Box display="flex" gap={2}>
              <Button variant="contained" color="inherit" onClick={close}>
                Отменить
              </Button>
              <Button variant="contained" onClick={() => formik.submitForm()} disabled={isLoading || !formik.isValid}>
                Сохранить
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PaymentForm;
