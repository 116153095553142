import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { rtkBaseQuery } from '../../helpers/rtkquery';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: rtkBaseQuery('/products'),
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
      providesTags: ['Products'],
    }),
    getProductById: builder.query({
      query: (id) => `${id}`,
      providesTags: ['Products'],
    }),
    fetchProductsByMerchantId: builder.mutation({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
    }),
    getProductsByMerchantId: builder.query({
      query: (params) => ({ url: '', params }),
      keepUnusedDataFor: 0,
    }),
    createProduct: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Products'],
    }),
    updateProduct: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'POST',
        body: { ...body, _method: 'PUT' },
      }),
      invalidatesTags: (result, error, arg) => ['Products', { type: 'Products', id: arg.id }],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'POST',
        body: { _method: 'DELETE' },
      }),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useFetchProductsByMerchantIdMutation,
  useGetProductsByMerchantIdQuery,
} = productsApi;
