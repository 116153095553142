import { Delete, Download } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useDeleteApplicationMutation, useGetApplicationListQuery } from '../../app/services/applicationsApi';
import LayoutList from '../../components/LayoutList';
import { CURRENCY_SUFFIX } from '../../constants/vars';
import { formatNumber, renderFullName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';
import { toast } from 'react-toastify';

interface Props {}

const SalesListPage: FC<Props> = () => {
  const { t, locale } = useLocale();

  const [deleteItem] = useDeleteApplicationMutation();

  const handleDeleteItem = (id: number) => {
    deleteItem(id)
      .unwrap()
      .then(() => toast.success(t('delete.success', 'Успешно удалено')))
      .catch((error) => {
        toast.error(t('delete.error', 'Ошибка удаления'));
        console.error(error);
      });
  };

  const cols = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'product',
      label: t('product', 'Товар'),
      render: (row: any) =>
        (row.products[0]?.title[locale] || '') + (row.products.length > 1 ? ' и еще ' + (row.products.length - 1) : ''),
    },
    {
      id: 'client',
      label: t('client', 'Клиент'),
      render: (row: any) => renderFullName(row.client),
    },
    {
      id: 'supplier',
      label: t('supplier', 'Поставщик'),
      render: (row: any) => renderFullName(row.merchant, 'contact_1'),
    },
    {
      id: 'sold_at',
      label: t('saleDate', 'Дата продажи'),
      render: (row: any) => new Date(row.sold_at).toLocaleDateString('ru'),
    },
    {
      id: 'price',
      label: t('saleAmount', 'Сумма продажи'),
      render: (row: any) => formatNumber(row.price) + CURRENCY_SUFFIX,
    },
    {
      id: 'loan_term',
      label: t('buyTerm', 'Срок покупки, мес.'),
    },
    {
      id: 'file',
      label: '',
      render: (row: any) => (
        <IconButton
          color="info"
          size="small"
          href={row.attachments.items[0].url}
          download
          onClick={(e) => e.stopPropagation()}
        >
          <Download />
        </IconButton>
      ),
    },
  ];

  return (
    <LayoutList
      title={t('sales', 'Продажи')}
      cols={cols}
      query={useGetApplicationListQuery}
      filter="full"
      deleteQuery={handleDeleteItem}
      noEdit
    />
  );
};

export default SalesListPage;
