import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductSelectorRow from './ProductSelectorRow';
import RequiredSign from './RequiredSign';

interface ProductsSelectorProps {
  items: SelectedProduct[];
  required?: boolean;
  handleChange: (items: SelectedProduct[]) => void;
}

interface SelectedProduct {
  id?: number;
  quantity: number;
  merchant_id?: number;
}

const baseRow = { id: undefined, quantity: 1, merchant_id: undefined };

const ProductsSelector: FC<ProductsSelectorProps> = ({ items, handleChange, required = false }) => {
  const { t } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(items.length ? items : [baseRow]);

  useEffect(() => {
    handleChange(selectedProducts);
  }, [selectedProducts]);

  const handleAddProduct = useCallback(() => {
    setSelectedProducts((prev) => [...prev, baseRow]);
  }, []);

  const handleDeleteProduct = useCallback((index: number) => {
    setSelectedProducts((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleChangeValue = useCallback((index: number, row: SelectedProduct) => {
    setSelectedProducts((prev) => [...prev.slice(0, index), row, ...prev.slice(index + 1)]);
  }, []);

  return (
    <Stack alignItems="flex-start" gap={2}>
      <Typography variant="body2" mb={-2}>
        {t('product.selection', 'Выбор товара')}
        {required && <RequiredSign />}
      </Typography>
      {selectedProducts.map((item: SelectedProduct, index: number) => (
        <ProductSelectorRow
          key={index}
          index={index}
          data={item}
          onChange={handleChangeValue}
          onDelete={handleDeleteProduct}
          selectedProducts={selectedProducts}
        />
      ))}
      <Button onClick={handleAddProduct} variant="contained" color="info">
        {t('product.add', 'Добавить товар')}
      </Button>
    </Stack>
  );
};

export default ProductsSelector;
