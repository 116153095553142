import SuppliersCreatePage from "./SuppliersCreatePage";
import SuppliersDetails from "./SuppliersDetailsPage";
import SuppliersListPage from "./SuppliersListPage";
import SuppliersUpdatePage from "./SuppliersUpdatePage";

const Suppliers = {
  List: SuppliersListPage,
  Create: SuppliersCreatePage,
  Details: SuppliersDetails,
  Update: SuppliersUpdatePage,
};

export default Suppliers;
