import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const callCenterApi = createApi({
  reducerPath: 'callCenterApi',
  baseQuery: rtkBaseQuery('/loan-call-centers'),
  endpoints: (builder) => ({
    getLoanCallCenters: builder.query({
      query: (params) => ({ url: '', params }),
    }),

    getLoanCallCenterById: builder.query({
      query: (id) => `/${id}`,
    }),
  }),
});

export const { useGetLoanCallCentersQuery, useGetLoanCallCenterByIdQuery } = callCenterApi;
