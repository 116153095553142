import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { createElement, useState } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return createElement(Slide, {
    direction: "down",
    ref,
    ...props,
  });
});

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);

  const openDialog = (content: React.ReactNode) => {
    setOpen(true);
    setDialogContent(content);
  };

  const closeDialog = () => {
    setOpen(false);
    setDialogContent(null);
  };

  const renderDialog = () => {
    return createElement(
      Dialog,
      {
        open,
        onClose: closeDialog,
        // keepMounted,
        TransitionComponent: Transition,
      },
      dialogContent
    );
  };

  return {
    open,
    openDialog,
    closeDialog,
    dialogContent,
    setDialogContent,
    renderDialog,
  };
};

export default useDialog;
