import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import useUser from "../hooks/useUser";

interface WelcomeProps {}

const Welcome: FC<WelcomeProps> = () => {
  const { user } = useUser();

  return (
    <Stack gap={4}>
      <Typography variant="h3">
        Добро пожаловать, {user?.first_name}!
      </Typography>
      <Typography>
        Воспользуйтесь навигацией слева для перехода в нужный раздел
      </Typography>
    </Stack>
  );
};

export default Welcome;
